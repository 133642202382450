import { Component, OnInit } from "@angular/core";
import { EMPTY, Subject } from "rxjs";
import { catchError, switchMap, takeUntil, tap } from "rxjs/operators";
import { AutoDestroyService } from "src/app/common/services/autodestroy.service";
import { SnackBarService } from "src/app/common/services/snack-bar.service";
import { EditUserAvailabilitiesService } from "./services/edit-users-availabilities.service";
import { PeriodInfo } from "src/app/common/models/period-info";

export const EDIT_USERS_COMPONENT_SELECTOR = "app-edit-users-availabilities";

@Component({
  selector: EDIT_USERS_COMPONENT_SELECTOR,
  templateUrl: "./edit-users-availabilities.component.html",
  styleUrls: ["./edit-users-availabilities.component.scss"],
  providers: [AutoDestroyService],
})
export class EditUsersAvailabilitiesComponent implements OnInit {
  currentMonth = new Date().getMonth();
  firstDateOfCurrentMonth: Date;
  lastDateOfCurrentMonth: Date;
  changeMonthClicked$: Subject<number> = new Subject();
  users: any[] = [];
  selectedUsers: any[] = [];
  availabilities = [];
  loading = false;
  period: PeriodInfo = null;

  get numberOfDaysInCurrentMonth() {
    const days = Array(this.lastDateOfCurrentMonth?.getDate() ?? 31)
      .fill(0)
      .map((_, i) => i + 1);
    return days;
  }
  get weekendDays() {
    const weekendDays = this.numberOfDaysInCurrentMonth.filter((day) => {
      const date = new Date(new Date().getFullYear(), this.currentMonth, day);
      return date.getDay() === 0 || date.getDay() === 6;
    });
    return weekendDays;
  }
  get visibleUsers() {
    return this.selectedUsers.length > 0 ? this.users.filter((user) => this.selectedUsers.some((u) => user.email === u.email)) : this.users;
  }
  constructor(private editAvailabilitiesService: EditUserAvailabilitiesService, private destroy$: AutoDestroyService, private snackBarService: SnackBarService) {}

  ngOnInit(): void {
    this.subscribeToChangeMonthClicked();
    this.getUsers();
    this.changeMonthClicked$.next(0);
  }

  subscribeToChangeMonthClicked(): void {
    this.changeMonthClicked$
      .pipe(
        tap((i) => {
          this.currentMonth += i;
          this.availabilities = [];
          this.loading = true;
          this.calculateMonthDatesRange();
          this.getPeriodInfo();
        }),
        switchMap(() => {
          return this.editAvailabilitiesService.getUsersAvailabilities(this.firstDateOfCurrentMonth).pipe(
            catchError(() => {
              this.snackBarService.error("Es war nicht möglich die Verfügbarkeiten vom Server zu laden.");
              return EMPTY;
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((usersAvailabilitiesList) => {
        const availabilitiesByDay = [];
        this.loading = false;
        if (Object.keys(usersAvailabilitiesList).length === 0) return;
        Object.keys(usersAvailabilitiesList).forEach((key) => {
          const tempList: string[][] = Object.values(usersAvailabilitiesList[key]);
          const allCodes: string[] = tempList.reduce((acc, curr) => acc.concat(curr), []);
          const uniqueCodes: string[] = Array.from(new Set(allCodes));
          availabilitiesByDay[key] = [...uniqueCodes];
        });
        this.availabilities = [...availabilitiesByDay];
      });
  }

  getUsers(): void {
    this.editAvailabilitiesService
      .getUsers()
      .pipe(
        catchError(() => {
          this.snackBarService.error("Die User-Liste konnte nicht geladen werden.");
          return EMPTY;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((users) => {
        this.users = users.filter((user) => user.roles && user.roles.length > 0);
      });
  }
  getPeriodInfo(): void {
    this.editAvailabilitiesService
      .getPeriodInfo(this.firstDateOfCurrentMonth)
      .pipe(takeUntil(this.destroy$))
      .subscribe((periodInfo) => {
        this.period = periodInfo;
      });
  }

  calculateMonthDatesRange(): void {
    this.firstDateOfCurrentMonth = new Date(new Date().getFullYear(), this.currentMonth, 1);
    this.lastDateOfCurrentMonth = new Date(new Date().getFullYear(), this.currentMonth + 1, 0);
  }
  onAvailabilityChange(e, user, day: number): void {
    const date = new Date(new Date().getFullYear(), this.currentMonth, day);
    this.editAvailabilitiesService
      .changeUserAvailability(user, date, e.target.checked)
      .pipe(
        takeUntil(this.destroy$),
        catchError(() => {
          e.target.checked = !e.target.checked;
          this.snackBarService.error("Ein Fehler ist aufgetreten...");
          return EMPTY;
        })
      )
      .subscribe();
  }
}
