import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Utils as UtilsApp } from "../../utils/Utils.app";

@Injectable({
  providedIn: "root",
})
export class ExcelExportService {
  constructor(private readonly http: HttpClient, private snackBar: MatSnackBar) {}

  exportToExcel({ start, end }, url: string, useDateParameters: boolean = true) {
    const { startDate, endDate } = UtilsApp.formatStartEndForBackend(start, end);
    this.http
      .get(useDateParameters ? `${url}/${startDate}/${endDate}` : `${url}`, {
        responseType: "arraybuffer",
        observe: "response",
      })
      .subscribe(
        (response) => {
          const [, filename] = response.headers.get("content-disposition").split(";");
          this.downloadFile(response.body, { startDate, endDate }, filename.split("=")[1]);
        },
        (error) => {
          // response is arraybuffer
          const decoder = new TextDecoder("utf-8");
          this.snackBar
            .open(decoder.decode(error.error), "Export dennoch ausführen", {
              duration: 15_000,
              panelClass: "excel-export-snackbar",
            })
            .onAction()
            .subscribe(() => {
              this.forceExportToExcel({ start, end }, url, useDateParameters);
            });
        }
      );
  }

  private forceExportToExcel({ start, end }, url: string, useDateParameters: boolean = true) {
    const { startDate, endDate } = UtilsApp.formatStartEndForBackend(start, end);
    this.http
      .get(useDateParameters ? `${url}/${startDate}/${endDate}?ignoreMissingColors=true` : `${url}?ignoreMissingColors=true`, {
        responseType: "arraybuffer",
        observe: "response",
      })
      .subscribe(
        (response) => {
          const [, filename] = response.headers.get("content-disposition").split(";");
          this.downloadFile(response.body, { startDate, endDate }, filename.split("=")[1]);
        },
        (error) => {
          // response is arraybuffer
          const decoder = new TextDecoder("utf-8");
          this.snackBar.open(decoder.decode(error.error), "OK", {
            duration: 15000,
            panelClass: "excel-export-snackbar",
          });
        }
      );
  }

  private downloadFile(data: any, { startDate, endDate }, name?: string) {
    const blob = new Blob([data], { type: "application/ms-excel" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    if (name) {
      a.download = name;
    } else {
      a.download = `Dispo-Table_${startDate}-${endDate}.xlsx`;
    }
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }
}
