import { Component, Inject, OnInit } from "@angular/core";
import { AuthService, UserData } from "../../common/services/auth.service";
import { AutoDestroyService } from "../../common/services/autodestroy.service";

export const HEADER_COMPONENT_SELECTOR = "app-header";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  providers: [AutoDestroyService],
})
export class HeaderComponent implements OnInit {
  currentUser: UserData;
  private showHead = true;
  activetab: string;

  constructor(private authService: AuthService, private destroy$: AutoDestroyService, @Inject("$rootScope") private $rootScope: any) {}

  ngOnInit(): void {
    this.$rootScope.$on("$routeChangeSuccess", (res) => {
      this.activetab = res.currentScope.$route.current.$$route.activetab;
    });

    this.authService.userData$.subscribe((res) => {
      this.currentUser = res;
    });
  }

  navigate(availabilities: string) {
    location.href = `/#!/${availabilities}`;
  }

  logout() {
    this.authService.logout();
  }
}
