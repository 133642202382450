import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {SportEventsService} from '../../common/services/sport-events.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import {Calendar} from 'primeng/calendar';
import {Competition, SportEvent, SportDef, Team} from '../../common/models/sport-events-page.models';
import {Utils} from '../../utils/Utils.app';
import {AutoDestroyService} from '../../common/services/autodestroy.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {trigger} from '@angular/animations';
import {DisabledTimeFn} from 'ng-zorro-antd';

@Component({
  animations: [trigger('', [])],
  selector: 'app-event-creator-form',
  templateUrl: './event-creator-form.component.html',
  styleUrls: ['./event-creator-form.component.scss'],
  providers: [
    AutoDestroyService
  ]
})
export class EventCreatorFormComponent implements OnInit {

  @ViewChild('start', {static: false}) startDatePicker: Calendar;

  formats: { value: string, viewValue: string }[] = [];
  sports: SportDef[] = [];
  selectedSportId = new BehaviorSubject<SportDef>(null);
  competitions: Observable<Competition[]>;
  teams: Observable<Team[]>;
  form: FormGroup;
  editMode = false;
  loading: boolean;

  constructor(
    private readonly sportEventService: SportEventsService,
    private readonly destroy$: AutoDestroyService,
    public dialogRef: MatDialogRef<EventCreatorFormComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: { event: SportEvent }
  ) {
    const {event} = data;
    if (event) {
      this.selectedSportId.next(event.sport);
      this.editMode = !!event;
    }
    this.sportEventService.getFormats()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(formats => {
        this.formats = formats.filter(Boolean).map(format => ({value: format, viewValue: format}));
      });

    this.sportEventService.getSports()
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(sportNames => {
        this.sports = sportNames as SportDef[];
      });

    this.buildForm(event);
  }


  ngOnInit(): void {

  }

  get formatValue() {
    return this.form.get('Format').value;
  }

  createItem() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;
    const formattedForm = this.formatForm();
    // Object.keys(formattedForm).forEach((k) => !formattedForm[k] && delete formattedForm[k]);

    if (this.editMode) {
      const objToSave = {
        ...this.data.event
      };
      Object.keys(formattedForm).forEach(key => {

        objToSave[Utils.lowerCaseFirstLetter(key)] = formattedForm[key];
      });

      return this.sportEventService.updateEvent(objToSave)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(s => {
          this.dialogRef.close(true);
        });
    }

    this.sportEventService.createEvent(formattedForm)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(s => {
        this.dialogRef.close(true);
      });
  }

  dateSelected(date: Date, key: string) {
    // this.form.patchValue({
    //   [key]: Utils.toFormatDate(date.toString(), 'dd.MM.yyyy HH:mm')
    // });
  }

  private formatForm() {
    const value = this.form.getRawValue();
    return {
      ...value,
      GameDay: value.GameDay ? +value.GameDay : undefined,
      ProgramStart: value.ProgramStart,
      Kickoff: value.Kickoff,
    };

    function formatDate(inputDate: string) {
      if (!inputDate) {
        return undefined;
      }
      const [date, time] = inputDate.split(' ');
      const [day, month, year] = date.split('.');
      return `${year}-${month}-${day}T${time}`;
    }
  }

  private buildForm(event?: SportEvent) {
    this.setupDatasource();

    if (event) {
      return this.buildEditForm(event);
    }
    this.buildCreateForm();

  }

  private buildEditForm(event: SportEvent) {
    this.form = new FormGroup({
      SportName: new FormControl({value: event.sportName, disabled: true}, [Validators.required]),
      CompetitionName: new FormControl({value: event.competitionName, disabled: true}, [Validators.required]),
      CompetitionLabel: new FormControl({value: event.competitionLabel, disabled: false}, [Validators.required]),
      GameDay: new FormControl(event.gameDay, []),
      ProgramStart: new FormControl(new Date(event.programStart), []),
      TeamNameHome: new FormControl(event.teamNameHome, []),
      TeamNameAway: new FormControl(event.teamNameAway, []),
      Format: new FormControl(event.format, []),
      isAutoSyncDisabled: new FormControl(event.isAutoSyncDisabled, [])
    });

    this.teams = this.sportEventService.getTeams(this.data.event.sportId);
  }

  private buildCreateForm() {
    const debounce = 200;

    const teamHomeIdControl = new FormControl('', [Validators.required]);
    teamHomeIdControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      filter(Boolean),
      distinctUntilChanged(),
      debounceTime(debounce),
      switchMap(id => this.teams.pipe(take(1), map(teams => teams.find(t => t.id === Number(id)))))
    )
      .subscribe(team => {
        this.form.patchValue({
          TeamHomeName: team.name
        });
      });

    const teamAwayIdControl = new FormControl('', [Validators.required]);
    teamAwayIdControl.valueChanges.pipe(
      takeUntil(this.destroy$),
      filter(Boolean),
      distinctUntilChanged(),
      debounceTime(debounce),
      switchMap(id => this.teams.pipe(take(1), map(teams => teams.find(t => t.id === Number(id)))))
    )
      .subscribe(team => {
        this.form.patchValue({
          TeamAwayName: team.name
        });
      });

    const sportNameControl = new FormControl('', [Validators.required]);
    sportNameControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(),
        debounceTime(debounce),
        tap(sportId => this.selectedSportId.next(sportId))
      ).subscribe(sportId => {
      this.form.patchValue({
        CompetitionId: '',
        TeamHomeId: null,
        TeamAwayId: null,
        SportId: sportId
      });
    });
    this.form = new FormGroup({
      SportName: sportNameControl,
      SportId: new FormControl('', [Validators.required]),
      CompetitionId: new FormControl('', [Validators.required]),
      CompetitionLabel: new FormControl('', []),
      CompetitionName: new FormControl('', []),
      GameDay: new FormControl('', []),
      ProgramStart: new FormControl('', [Validators.required]),
      TeamHomeId: teamHomeIdControl,
      TeamHomeName: new FormControl('', []),
      TeamAwayId: teamAwayIdControl,
      TeamAwayName: new FormControl('', []),
      Format: new FormControl('', []),
      isAutoSyncDisabled: new FormControl(false, [])
    });
  }

  private setupDatasource() {
    this.teams = this.selectedSportId.pipe(
      filter(Boolean),
      switchMap((sportId: number) => this.sportEventService.getTeams(sportId)),
      shareReplay({
        refCount: true,
        bufferSize: 1
      })
    );

    this.competitions = this.selectedSportId.pipe(
      filter(Boolean),
      switchMap((sportId: number) => this.sportEventService.getCompetitions(sportId)),
      shareReplay({
        refCount: true,
        bufferSize: 1
      })
    );
  }

  disabledDateTime: DisabledTimeFn = () => ({
    nzDisabledHours: () => [],
    nzDisabledMinutes: () => [],
    nzDisabledSeconds: () => this.getSeconds()
  });

  range(start: number, end: number): number[] {
    const result: number[] = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  onOk(result: Date | Date[] | null): void {
  }

  private getSeconds() {
    const arr = [];
    for (let i = 0; i < 60; i++) {
      arr.push(i);
    }

    return arr;
  }
}
