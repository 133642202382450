import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { NzMessageService } from "ng-zorro-antd";
import { Observable, Subject } from "rxjs";
import { exhaustMap, filter, takeUntil, tap } from "rxjs/operators";
import { EmailTemplate } from "src/app/common/models/email-template";
import { IJobs, TYPE_JOBS } from "src/app/common/models/jobs";
import { Partner } from "src/app/common/models/partner";
import * as uuid from "uuid";
import { AutoDestroyService } from "../../../common/services/autodestroy.service";
import { SendOutService } from "../../services/send-out.service";
@Component({
  selector: "app-action-send-email",
  templateUrl: "./action-send-email.component.html",
  styleUrls: ["./action-send-email.component.scss"],
})
export class ActionSendEmailComponent implements OnInit {
  @Input() title: any;
  @Input() type: TYPE_JOBS;
  @Input() icon: any;
  @Output() hasFormDataBeenChanged = new EventEmitter<boolean>();
  form = new FormGroup({
    rangePicker: new FormControl("", [Validators.required]),
  });
  emailTemplate: EmailTemplate;
  emailEditionForm: FormGroup;
  partnersForm: FormGroup;
  isOpen = false;
  history: IJobs[];
  partners: Partner[] = [];
  editPartnerCache: { [key: string]: { edit: boolean; newEntry: boolean; data: Partner } } = {};
  saveEmailTemplateClicked$: Subject<{ globalSubmit: boolean }> = new Subject<{ globalSubmit: boolean }>();
  savePartnersClicked$: Subject<{ globalSubmit: boolean }> = new Subject<{ globalSubmit: boolean }>();
  partnersDataChanged: boolean = false;
  emailDataChanged: boolean = false;
  get isPartners() {
    return this.type === TYPE_JOBS.PARENT;
  }

  get isEmailConfigCollapseDisabled() {
    return this.emailEditionForm?.controls?.subject?.invalid || this.emailEditionForm?.controls?.body?.invalid;
  }
  get partnersArray() {
    return this.partnersForm?.controls?.partners as FormArray;
  }

  get isPartnersConfigCollapseDisabled() {
    return this.partnersForm?.invalid;
  }
  constructor(private sendOutService: SendOutService, private destroy$: AutoDestroyService, private message: NzMessageService) {}

  ngOnInit(): void {
    this.getJobs();
    this.getEmailTemplate();
    if (this.isPartners) {
      this.getPartners();
    }
  }

  getJobs() {
    this.sendOutService
      .getJobs(this.type)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.history = res;
      });
  }
  getEmailTemplate() {
    this.sendOutService
      .getEmailTemplates(this.type)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.emailTemplate = res.length ? res[0] : null;
        this.initEmailEditionForm();
        this.subscribeToSaveEmailTemplateClicked();
      });
  }
  getPartners() {
    this.sendOutService
      .getPartners()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.partners = res;
        this.initPartnersForm();
        this.subscribeToSaveAllPartnersClicked();
      });
  }

  formatingDateString(date) {
    let month = date.getMonth() + 1; // получаем номер месяца (0-11) и прибавляем 1, чтобы получить номер месяца в формате 1-12
    let day = date.getDate(); // получаем день месяца (1-31)
    let year = date.getFullYear(); // получаем год (полный формат)

    // форматируем дату в строку с нужным форматом
    let dateString = `${month.toString().padStart(2, "0")}.${day.toString().padStart(2, "0")}.${year}`;
    return dateString;
  }

  sendEmail() {
    const start = this.formatingDateString(this.form.get("rangePicker").value[0]);
    const end = this.formatingDateString(this.form.get("rangePicker").value[1]);

    this.sendOutService
      .emailPublishing(start, end, this.type)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res) => {
          const count = res.numEmails;

          if (count) {
            this.message.create("success", `Job wurde angelegt und enthält ${count} zu versendende Emails`);
          } else {
            this.message.create("info", `Job wurde angelegt, enthält jedoch keine Emails`);
          }
          this.getJobs();
        },
        (error) => {
          this.message.create("error", error.error.title);
        }
      );
  }

  open() {
    this.isOpen = true;
  }

  change(e) {}

  initEmailEditionForm() {
    this.emailEditionForm = new FormGroup({
      subject: new FormControl(this.emailTemplate?.subject ?? "", [Validators.maxLength(255)]),
      body: new FormControl(this.emailTemplate?.body ?? "", []),
      id: new FormControl(this.emailTemplate?.id ?? null),
      jobType: new FormControl(this.emailTemplate?.jobType ?? this.type),
    });
    this.emailEditionForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.emailDataChanged = true;
      this.hasFormDataBeenChanged.emit(true);
    });
  }
  initPartnersForm() {
    this.partnersForm = new FormGroup({
      partners: new FormArray(
        this.partners.map((partner) => {
          return this.addPartnerFormGroup(partner);
        })
      ),
    });
    this.partnersForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.partnersDataChanged = true;
      this.hasFormDataBeenChanged.emit(true);
    });
  }
  addPartnerFormGroup(partner: Partner) {
    return new FormGroup({
      label: new FormControl(partner.label, [Validators.required]),
      emailAddress: new FormControl(partner.emailAddress, [Validators.required, Validators.email]),
      id: new FormControl(partner.id),
    });
  }
  addNewPartner() {
    const newPartner = { label: null, emailAddress: null, type: this.type, id: uuid.v4() };
    this.partners.splice(this.partners.length, 0, newPartner);
    this.partners = [...this.partners];
    this.editPartnerCache[newPartner.id] = { data: { ...newPartner }, edit: true, newEntry: true };
    this.partnersArray.insert(this.partnersArray.length, this.addPartnerFormGroup(newPartner));
  }
  startEditingPartner(partner: Partner) {
    this.editPartnerCache[partner.id] = { data: { ...partner }, edit: true, newEntry: this.editPartnerCache[partner.id]?.newEntry ?? false };
  }
  cancelEditingPartner(formGroupIndex: number): void {
    const formGroup = this.partnersArray.controls[formGroupIndex] as FormGroup;
    const id = formGroup.get("id").value;
    if (id == null || (this.editPartnerCache[id].newEntry && formGroup.invalid)) {
      this.partnersArray.removeAt(formGroupIndex);
      this.partners.splice(formGroupIndex, 1);
      this.partners = [...this.partners];
      return;
    }
    formGroup.reset(this.editPartnerCache[id].data);
    this.editPartnerCache[id].edit = false;
  }

  deletePartner(formGroupIndex: number): void {
    this.sendOutService.deletePartner(this.partners[formGroupIndex].id).subscribe(() => {
      this.message.success("Deleted partner successfully");
      this.partners.splice(formGroupIndex, 1);
      this.partners = [...this.partners];
      this.partnersArray.removeAt(formGroupIndex);
    });
  }
  savePartner(index: number, globalSubmit: boolean = false): void {
    const formGroup = this.partnersArray.controls[index] as FormGroup;
    const id = formGroup.get("id").value;
    Object.values(formGroup.controls).forEach((control) => {
      if (control.invalid) {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
    if (formGroup.invalid) return;
    let request: Observable<Partner>;
    if (this.editPartnerCache[id].newEntry) {
      request = this.sendOutService.savePartner({ ...formGroup.value, id: null }).pipe(tap((res: Partner) => formGroup.get("id").setValue(res.id, { emitEvent: false })));
    } else {
      request = this.sendOutService.updatePartner(formGroup.value);
    }
    request.subscribe((res: Partner) => {
      this.partners = [...this.partners];
      this.partners[index] = res;
      delete this.editPartnerCache[id];
      if (!globalSubmit) {
        this.message.success(`Partner ${res.emailAddress} erfolgreich gespeichert`);
        if (!Object.entries(this.editPartnerCache).some(([key, value]) => value.edit)) {
          this.hasFormDataBeenChanged.emit(false);
        }
      }
    });
  }
  subscribeToSaveAllPartnersClicked() {
    this.savePartnersClicked$.pipe(takeUntil(this.destroy$)).subscribe(({ globalSubmit }) => {
      this.partnersArray?.controls.forEach((formGroup, index) => {
        if (this.editPartnerCache[formGroup.get("id").value]?.edit && formGroup.valid) {
          this.savePartner(index, globalSubmit);
        }
      });
      if (!globalSubmit) {
        this.partnersDataChanged = false;
        if (!this.emailDataChanged) {
          this.hasFormDataBeenChanged.emit(false);
        }
      }
    });
  }
  subscribeToSaveEmailTemplateClicked() {
    this.saveEmailTemplateClicked$
      .pipe(
        filter(() => this.emailEditionForm.valid),
        exhaustMap(({ globalSubmit }) => {
          return this.sendOutService.saveEmailTemplate(this.emailEditionForm.value).pipe(
            tap((res: EmailTemplate) => {
              this.emailEditionForm.controls["id"].setValue(res.id, { emitEvent: false });
              if (!globalSubmit) {
                this.message.success(`${this.type === TYPE_JOBS.FREELANCER ? "freelancer" : "partner"} Email Template erfolgreich gespeichert`);
                this.emailDataChanged = false;
                if (!this.partnersDataChanged) {
                  this.hasFormDataBeenChanged.emit(false);
                }
              }
            })
          );
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
