import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { BehaviorSubject, fromEvent, interval, merge } from "rxjs";
import { debounceTime, exhaustMap, shareReplay, take, takeUntil } from "rxjs/operators";
import { SportEvent } from "../common/models/sport-events-page.models";
import { AutoDestroyService } from "../common/services/autodestroy.service";
import { CostService } from "../common/services/cost.service";
import {
  DEFAULT_DATE_PERIOD,
  DEFAULT_DATE_PERIOD_DISPO,
  SportEventsService,
} from "../common/services/sport-events.service";
import { USER_ROLES_KEYS } from "../constants/sport-event.constant";
import { ColorUtils } from "../utils/ColorUtils";
import { ScrollbarUtils } from "../utils/ScrollbarUtils";

export const COST_OVERVIEW_SELECTOR = "app-cost-overview";

@Component({
  selector: COST_OVERVIEW_SELECTOR,
  templateUrl: "./cost-overview.component.html",
  styleUrls: ["./cost-overview.component.scss"],
  providers: [CostService, AutoDestroyService],
})
export class CostOverviewComponent implements OnInit, AfterContentInit {
  USER_ROLES_KEYS = USER_ROLES_KEYS;
  data: SportEvent[][] = [];
  tableHeight: string = "800px";
  editable: boolean = false;
  scrolled = false;
  scrollBarHeight = 0;
  rowGroupMetadata: { [key: string]: { index: number; size: number } };
  selectedDateRange = DEFAULT_DATE_PERIOD_DISPO;
  competitionColors: { [key: string]: string } = {};
  loading: boolean;
  startSkeleton: boolean = false;
  isLoading: boolean = true;
  form: FormGroup = new FormGroup({
    rangePicker: new FormControl("", [Validators.required]),
  });
  ranges = {
    "Aktuelle Saison": [DEFAULT_DATE_PERIOD.start, DEFAULT_DATE_PERIOD.end],
  };
  dateRangeChanged$: BehaviorSubject<{ start: number; end: number }> =
    new BehaviorSubject<{
      start: number;
      end: number;
    }>(this.selectedDateRange);
  defaultVpu$ = this.sportsService.getVPUDefault().pipe(shareReplay(1));
  getTextColor = (color: any) => ColorUtils.getTextColor(color);
  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 20;
  }

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly destroy$: AutoDestroyService,
    private readonly elRef: ElementRef,
    private costService: CostService,
    private sportsService: SportEventsService
  ) {}

  ngOnInit() {
    this.setDefaultDateRange();
    this.subscribeToRangeControlChanges();
    this.subscribeToRangeValueChanges();
  }

  subscribeToRangeControlChanges() {
    this.form
      .get("rangePicker")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((date) => {
        this.selectedDateRange = {
          start: +date[0],
          end: +date[1],
        };
        if (this.loading) {
          this.loading = false;
        }
        this.dateRangeChanged$.next(this.selectedDateRange);
      });
  }

  subscribeToRangeValueChanges() {
    this.dateRangeChanged$
      .pipe(
        exhaustMap(() =>
          this.costService.getCostSummary(this.selectedDateRange)
        ),
        takeUntil(this.destroy$)
      )
      .subscribe(({ events, competitionColors }) => {
        this.competitionColors = competitionColors;
        this.data = events;
        this.loading = false;
        this.startSkeleton = false;
        this.resizeTable();
      });
  }

  ngAfterContentInit(): void {
    this.scrollBarHeight = ScrollbarUtils.getScrollBarWidth();
    this.initResizeListener();
  }

  trackBy(index, item: SportEvent) {
    return item.id;
  }

  private initResizeListener() {
    const interval$ = interval(200).pipe(take(20));
    merge(fromEvent(window, "resize"), interval$)
      .pipe(debounceTime(100))

      .pipe(takeUntil(this.destroy$))

      .subscribe(() => this.resizeTable());
  }

  private resizeTable() {
    const { top } = this.elRef.nativeElement.getBoundingClientRect();
    const ww = window.innerWidth;
    this.tableHeight = `${
      window.innerHeight - 30 - top - 5 - (ww < 1200 ? this.scrollBarHeight : 0)
    }px`; // -72
    this.cdr.detectChanges();
  }

  private setDefaultDateRange() {
    this.form
      .get("rangePicker")
      .setValue([this.selectedDateRange.start, this.selectedDateRange.end]);
  }
  export(){
    this.costService.exportCostSummary(this.selectedDateRange);
  }
}
