import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { EmailTemplate } from "src/app/common/models/email-template";
import { IJobs, TYPE_JOBS } from "src/app/common/models/jobs";
import { Partner } from "src/app/common/models/partner";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SendOutService {
  constructor(private readonly http: HttpClient) {}

  emailPublishing(start: string, end: string, type: TYPE_JOBS) {
    return this.http.post<any>(environment.rootUrl + `/api/EmailPublishing/jobs/${type}?from=${start}&to=${end}`, {});
  }

  getJobs(type: TYPE_JOBS): Observable<IJobs[]> {
    return this.http.get<IJobs[]>(environment.rootUrl + `/api/EmailPublishing/jobs?type=${type}`);
  }

  getEmailTemplates(type: TYPE_JOBS) {
    return this.http.get<EmailTemplate[]>(environment.rootUrl + `/api/EmailPublishing/emailTemplate?type=${type}`);
  }
  saveEmailTemplate(template: EmailTemplate) {
    if (template.id != null) {
      return this.http.put(environment.rootUrl + `/api/EmailPublishing/emailTemplate/${template.id}`, template).pipe(catchError((err) => EMPTY));
    }
    return this.http.post(environment.rootUrl + `/api/EmailPublishing/emailTemplate`, template).pipe(catchError((err) => EMPTY));
  }

  getPartners() {
    return this.http.get<Partner[]>(environment.rootUrl + `/api/EmailPublishing/partners`);
  }

  savePartner(partner: Partner) {
    return this.http.post(environment.rootUrl + `/api/EmailPublishing/partners`, partner).pipe(catchError((err) => EMPTY));
  }
  updatePartner(partner: Partner) : Observable<Partner> {
    return this.http.put<Partner>(environment.rootUrl + `/api/EmailPublishing/partners/${partner.id}`, partner).pipe(catchError((err) => EMPTY));
  }
  deletePartner(id: number) {
    return this.http.delete(environment.rootUrl + `/api/EmailPublishing/partners/${id}`);
  }
}
