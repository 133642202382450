export interface IJobs {
  numEmails: number;
  errors: JobError[];
  job: {
    id: number;
    from: string;
    to: string;
    created: string;
    jobType: number;
    status: JOB_STATUS;
    triggeredBy: string;
    emails: any[];
  };
}
export interface JobError {
  loginName: string;
  userIdentifier: string;
  emailAddress: string;
}

export enum TYPE_JOBS {
  FREELANCER,
  PARENT,
}
export enum JOB_STATUS {
  SAVE,
  SYNC,
  CHECK,
  EXCLAMATION,
  TIMES,
}
