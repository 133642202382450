import { Component, Inject, OnInit, ViewChildren } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd";
import { TYPE_JOBS } from "../common/models/jobs";
import { AutoDestroyService } from "../common/services/autodestroy.service";
import { ActionSendEmailComponent } from "./components/action-send-email/action-send-email.component";

export const SEND_OUT_SELECTOR = "app-send-out";

@Component({
  selector: SEND_OUT_SELECTOR,
  templateUrl: "./send-out.component.html",
  styleUrls: ["./send-out.component.scss"],
  providers: [AutoDestroyService],
})
export class SendOutComponent implements OnInit {
  TYPE_JOBS = TYPE_JOBS;
  changed: boolean = false;
  @ViewChildren(ActionSendEmailComponent) actionSendEmailComponents: Array<ActionSendEmailComponent>;

  constructor(@Inject("routeSvc") private routeSvc: any, private message: NzMessageService) {}

  ngOnInit(): void {
    this.routeSvc.setDataChangedStatus(false);
  }

  saveAllConfiguration() {
    let anyFormInvalid: boolean = false;
    this.actionSendEmailComponents.forEach((actionSendEmailComponent) => {
      if (actionSendEmailComponent.emailEditionForm.invalid || actionSendEmailComponent.partnersForm?.invalid) {
        anyFormInvalid = true;
      }
      actionSendEmailComponent.savePartnersClicked$.next({ globalSubmit: true });
      actionSendEmailComponent.saveEmailTemplateClicked$.next({ globalSubmit: true });
    });
    if (anyFormInvalid) {
      this.message.error("Please fill all required fields");
    } else {
      this.message.success("Saved all data successfully");
      this.routeSvc.setDataChangedStatus(false);
    }
  }

  onEditChange(modified: boolean) {
    this.routeSvc.setDataChangedStatus(modified);
  }
}
