import {Utils} from '../../utils/Utils';

export class ContentProductionEvent {
  static COUNTER = 0;
    isAutoSyncDisabled: boolean;

  constructor() {
    this.id = Math.random() + ++ContentProductionEvent.COUNTER;
    this.fake = true;
  }

  'id': number;
  'vpu': string;
  'sportEventId': number;
  'competitionName': string;
  competitionIdent: string;
  'competitionLabel': string;
  'programStart': string;
  'programEnd': string;
  'gameDay': number;
  'teamNameHome': string;
  'teamNameAway': string;
  'format': string;
  'roleCommenter': string;
  'roleRegie': string;
  'roleEditor': string;
  'rolePresenter': string;
  'roleExpert': string;
  'roleSocial': string;
  'roleAdditionals': string;
  'color': string;
  'lastModDateUser': string;
  'lastModDateAutoSync': string;
  'isDeleted': boolean;
  'sportName': string;
  'sportId': number;
  'sport': any;
  dateToGetAvailableUsers?: string;
  virtualMatchDay?: number;
  dateFormatted?: string;
  groupName?: string;
  // colors - key = name of the field, value = code of the color
  colors?: {
    [key: string]: string
  }
  fake?: boolean;


  static serialize(obj: ContentProductionEvent) {
    const result = {} as ContentProductionEvent;
    ContentProductionEvent.keysToStoreOnBackend.forEach(key => {
      result[key] = obj[Utils.lowerCaseFirstLetter(key)]
    });
    return result;
  }

  static keysToStoreOnBackend = [
    'id',
    'contentProductionEventId',
    'competitionIdent',
    'competitionName',
    'competitionLabel',
    'format',
    'teamNameHome',
    'teamNameAway',
    'sportName',
    'gameDay',
    'programStart',
    'programEnd',
    'kickoff',
    'roleCommenter',
    'roleRegie',
    'rolePresenter',
    'roleEditor',
    'roleExpert',
    'roleSocial',
    'roleAdditionals',
    'isDeleted',
    'isAutoSyncDisabled',
    'vpu',
    'info',
    'sportId',
    'content',
    'sport',
  ]
}
