import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DispositionAdminService {
  serviceDomain = '/api/DispositionAdmin/';

  constructor(
    private http: HttpClient
  ) {
  }

  getRoles(roleType: ROLE_TYPE) {
    return this.http.get<any>(environment.rootUrl + this.serviceDomain + 'roles?userUsage=' + roleType);
  }

  getUsers(roleType: ROLE_TYPE) {
    let roleTypeString = '';
    if (roleType !== ROLE_TYPE.ALL) {
      roleTypeString = '?userUsage=' + roleType;
    }
    return this.http.get<any>(environment.rootUrl + this.serviceDomain + 'users' + roleTypeString);
  }
}

export enum ROLE_TYPE {
  DISPO,
  CONTENT_PROD,
  ALL = 999,
}
