import { Clipboard } from "@angular/cdk/clipboard";
import { Component, Input, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { JOB_STATUS, JobError } from "src/app/common/models/jobs";

@Component({
  selector: "app-job-status",
  templateUrl: "./job-status.component.html",
  styleUrls: ["./job-status.component.scss"],
})
export class JobStatusComponent implements OnInit {
  @Input() status: JOB_STATUS;
  @Input() errors: JobError[] = [];
  popoverVisible: boolean = false;
  statusIcons = {
    [JOB_STATUS.SAVE]: {
      icon: "fa-save",
      tooltip: "Warten auf erstmalige Ausführung des Email-Jobs",
    },
    [JOB_STATUS.SYNC]: {
      icon: "fa-sync",
      tooltip: "Der Job wird noch initialisiert bzw. läuft gerade zum ersten Mal",
    },
    [JOB_STATUS.CHECK]: {
      icon: "fa-check",
      tooltip: "Der Job wurde erfolgreich ausgeführt",
    },
    [JOB_STATUS.EXCLAMATION]: {
      icon: "fa-exclamation-triangle",
      tooltip: "Der Job wird noch wiederholt, es gibt jedoch bereits mindestens einen Fehler",
    },
    [JOB_STATUS.TIMES]: {
      icon: "fa-times",
      tooltip: "Der Job enthält mindestens einen endgültig fehlgeschlagenen Email-Versand",
    },
  };

  get jobStatus(): typeof JOB_STATUS {
    return JOB_STATUS;
  }
  get isClickable() {
    return (this.status === JOB_STATUS.EXCLAMATION || this.status === JOB_STATUS.TIMES) && this.errors.length > 0;
  }
  constructor(private cliboardService: Clipboard, private snackBar: MatSnackBar) {}

  ngOnInit(): void {}

  openErrorDetail() {
    if (!this.isClickable) return;
    this.popoverVisible = !this.popoverVisible;
  }
  copyError() {
    this.cliboardService.copy(
      this.errors
        .map((e) => {
          return `${e.loginName ? e.loginName : e.userIdentifier} (${e.emailAddress})`;
        })
        .join("\n")
    );
    this.snackBar.open("Copied to clipboard", null, { duration: 2000 });
  }
}
