
export class Utils {
  static toDate(date: string) {
    return new Date(date);
  }

  static dateSorterFn(o1, o2, key: string = 'programStart') {
    const dateSortResult = +Utils.toDate(o1[key]) - +Utils.toDate(o2[key]);
    if(dateSortResult !== 0){
      return dateSortResult
    }
    return new Intl.Collator().compare(o1.teamNameHome, o2.teamNameHome)
  }

  static lowerCaseFirstLetter(string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
  }
}
