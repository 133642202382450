export const USER_ROLES_KEYS = ['roleCommenter', 'roleRegie', 'roleEditor', 'rolePresenter', 'roleExpert', 'roleSBCProducer', 'roleMaz'];
export const USER_ROLES_KEYS_CONTENT = ['roleCommenter', 'roleRegie', 'roleEditor', 'rolePresenter', 'roleExpert'];
export const USER_ROLE_COLUMN_STARTING_INDEX = 8;
export const USER_ROLE_COLUMN_STARTING_INDEX_CONTENT = 5;

export function getAvailableUsersRoleKey(key: string) {
  return key + AVAILABLE_USERS_ADDITIONAL_KEY;
}

export const AVAILABLE_USERS_ADDITIONAL_KEY = 'UsersArray';
export const USERS_ERROR_KEY = 'Error';

export const DEFAULT_USER = 'N.N';
