import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(
    @Inject('tokenSvc') private tokenSvc: any
  ) {
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${this.tokenSvc.GetToken()}`)
    });

    return next.handle(authReq);

    // return next.handle(authReq).pipe(
    //   catchError((error) => {
    //     if (error.status === 401) {
    //       // location.href = '/login';
    //       // this.router.navigate(['/login']);
    //     }
    //     return throwError(error);
    //   })
    // );
  }
}
