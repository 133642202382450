import { Component, Inject, OnInit } from "@angular/core";
import { SportEventsService } from "../../../common/services/sport-events.service";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AutoDestroyService } from "../../../common/services/autodestroy.service";
import { catchError, takeUntil } from "rxjs/operators";
import { of } from "rxjs";

@Component({
  selector: "app-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"],
  providers: [AutoDestroyService],
})
export class UserDetailsComponent implements OnInit {
  dispoData: any = [];
  contentProductionData = [];
  selectedTabIndex: number = 0;
  constructor(private sportEventService: SportEventsService, @Inject(MAT_DIALOG_DATA) public data: any, private destroy: AutoDestroyService) {
    this.sportEventService
      .getUserDetailsGrouped(data.user.loginName)
      .pipe(
        takeUntil(this.destroy),
        catchError(() => {
          return of({
            dispo: mock,
            contentProduction: [],
          });
        })
      )
      .subscribe((r) => {
        this.dispoData = r.dispo;
        this.contentProductionData = r.contentProduction;
        this.setSelectedTab();
      });
  }

  ngOnInit(): void {}

  setSelectedTab(): void {
    if (this.dispoData.length > 0) {
      this.selectedTabIndex = 0;
    } else {
      this.selectedTabIndex = 1;
    }
  }
}

const mock = [
  {
    id: 2,
    seasonBegin: 2019,
    dayOfPlanning: "2020-05-16T00:00:00",
    user: null,
    sportEventId: 99,
    sportEvent: {
      id: 99,
      sportEventId: 4954,
      competitionIdent: 7,
      competitionName: "3. Liga",
      competitionLabel: "3. Liga",
      programStart: "2020-05-16T13:15:00",
      gameDay: 16,
      teamNameHome: "Krefelder FC Uerdingen 05",
      teamNameAway: "FC Viktoria Köln",
      format: null,
      roleCommenter: "M. Schlecht",
      roleRegie: "M. Schlecht",
      roleEditor: null,
      rolePresenter: null,
      roleExpert: null,
      roleMaz: null,
      roleAdditionals: null,
      lastModDateUser: "2020-08-05T23:34:29.8936651",
      lastModDateAutoSync: "2020-04-17T09:54:56.4642235",
      isDeleted: false,
      sportName: "Fußball",
    },
  },
  {
    id: 5,
    seasonBegin: 2019,
    dayOfPlanning: "2020-05-16T00:00:00",
    user: null,
    sportEventId: 100,
    sportEvent: {
      id: 100,
      sportEventId: 4955,
      competitionIdent: 7,
      competitionName: "3. Liga",
      competitionLabel: "3. Liga",
      programStart: "2020-05-16T13:15:00",
      gameDay: 16,
      teamNameHome: "FC Würzburger Kickers",
      teamNameAway: "Hallescher FC",
      format: null,
      roleCommenter: "M. Schlecht",
      roleRegie: null,
      roleEditor: null,
      rolePresenter: null,
      roleExpert: null,
      roleMaz: null,
      roleAdditionals: null,
      lastModDateUser: "2020-08-06T21:26:01.1853698",
      lastModDateAutoSync: "2020-04-17T09:54:56.4697725",
      isDeleted: false,
      sportName: "Fußball",
    },
  },
];
