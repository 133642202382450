import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map, pluck, take, shareReplay, tap} from 'rxjs/operators';
import {Observable, ReplaySubject, of} from 'rxjs';
import {downgradeInjectable} from '@angular/upgrade/static';
// @ts-ignore
declare var angular: angular.IAngularStatic;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token = '';
  userData$: ReplaySubject<UserData> = new ReplaySubject<UserData>(1);

  constructor(
    private readonly http: HttpClient,
    @Inject('$rootScope') private $rootScope: any,
  ) {
    this.token = localStorage.getItem(AUTH_TOKEN_KEY) || '';

    this.getUser().subscribe();
    $rootScope.$on('userChanged', (event, user) => {
      if (user?.login) {
        this.getUser(user.login).subscribe();
      }
    });
  }

  hasWritePermission(): Observable<boolean> {
    return this.getUser()
      .pipe(
        pluck('hasDispoWriteAccess')
      );
  }

  getUser(loginName?): Observable<UserData> {
    const user = JSON.parse(localStorage.getItem(USER_KEY));

    if (user) {
      this.userData$.next(user);
      return of(user);
    }
    return this.http.get(environment.rootUrl + `/api/disposition/user/${loginName}`)
      .pipe(
        take(1),
        tap((res) => {
          this.userData$.next(res);
          localStorage.setItem(USER_KEY, JSON.stringify(res));
        })
      );
  }

  public getToken() {
    this.http.post<any>(environment.rootUrl + '/api/auth/token', {
      login: 'admin',
      password: 'admin123'
    }).pipe(
      map(({token}) => token)
    ).subscribe(token => {
      this.token = token;
      localStorage.setItem(AUTH_TOKEN_KEY, token);
      location.reload();
    });


  }

  logout() {
    localStorage.clear();
    location.href = `/#!/login`;
  }
}

export class UserData {
  [key: string]: any
}

const AUTH_TOKEN_KEY = 'authToken';
const USER_KEY = 'user';

angular.module('mmi.tp.disposition').factory('authService', downgradeInjectable(AuthService));
