import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { cloneDeep, flatten, get } from "lodash";
import { Observable, ReplaySubject, fromEvent, zip } from "rxjs";
import { map, pluck, tap } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { USER_ROLES_KEYS, getAvailableUsersRoleKey } from "../../constants/sport-event.constant";
import { Utils as UtilsApp } from "../../utils/Utils.app";
import { SportEventCosts } from "../models/sport-event-costs";
import { ColorOptions, Competition, SportEvent, SportDef, Team, UsersAvailability } from "../models/sport-events-page.models";
import { ExcelExportService } from "./excel-export.service";
import { SportEventsSharedService } from "./sport-events.shared.service";

export class League {
  competitionIdent: string;
  competitionLabel: string;
  color?: string;
}

export interface VPUResponse {
  hasErrors: boolean;
  roleErrors: {
    role: string;
    validationError: string;
  }[];
}

@Injectable({
  providedIn: "root",
})
export class SportEventsService {
  private usersAvailability: UsersAvailability = null;
  private colors: ColorOptions[];
  private usersToEventsAssignerWorker: Worker;
  private workerResponse: ReplaySubject<any>;
  workerResponse$: Observable<{ events: SportEvent[]; full: boolean }>;
  formats: ReplaySubject<any>;
  sports: ReplaySubject<SportDef[]>;
  teams: {
    [key: string]: ReplaySubject<Team[]>;
  } = {};
  teamsBySportId: {
    [key: number]: ReplaySubject<Team[]>;
  } = {};

  competitions: {
    [key: number]: ReplaySubject<Competition[]>;
  } = {};
  infos: ReplaySubject<any>;

  constructor(private readonly http: HttpClient, private excelExportService: ExcelExportService, @Inject("appSvc") private appSvc: any) {
    this.initWorker();
  }

  getVPU(sportEventId) {
    return this.http.get<any>(environment.rootUrl + "/api/planning/vpu/" + sportEventId);
  }

  getVPUDefault() {
    return this.http.get<any>(environment.rootUrl + "/api/planning/vpu/defaultlabel", { responseType: "text" as "json" });
  }

  exportToExcel({ start, end } = DEFAULT_DATE_PERIOD) {
    this.excelExportService.exportToExcel({ start, end }, environment.rootUrl + `/api/planning/events/export`);
  }

  update(event: SportEvent): Observable<any> {
    return this.http.put(environment.rootUrl + "/api/planning/events/planning", SportEvent.serialize(event));
  }

  createEvent(event: Partial<SportEvent>): Observable<any> {
    return this.http.post(environment.rootUrl + "/api/planning/events", event);
  }

  updateEvent(event: Partial<SportEvent>): Observable<any> {
    return this.http.put(environment.rootUrl + "/api/planning/events/planning", event);
  }

  getDeletedSportEvents() {
    return this.http.get<SportEvent[]>(environment.rootUrl + `/api/planning/events/deleted`);
  }

  getSportCosts({ start, end } = DEFAULT_DATE_PERIOD): Observable<SportEventsWithMetadata> {
    const { startDate, endDate } = UtilsApp.formatStartEndForBackend(String(start), String(end));
    const urlFragment = `/api/costs/costs?from=${startDate}&to=${endDate}`;
    return zip(this.http.post<SportEventCosts[]>(environment.rootUrl + urlFragment, {}), this.getColorsByDate({ start, end }), this.getColorsForCompetitions(), this.getUsers({ start, end })).pipe(
      map(([costs, colors, competitionColors, users]) => {
        this.colors = colors;
        const eventsGrouped = [];
        costs.forEach((cost) =>
          eventsGrouped.push(
            cost.costsPerGame.map((costPerGame) => {
              return { ...costPerGame, totalGameCosts: cost.alloverCosts };
            })
          )
        );
        const formattedEvents = this.addPropsToEvents(eventsGrouped, colors);
        const eventsWithUsers = formattedEvents ? this.populateEventsWithAvailableUsers(formattedEvents, users) : ([] as SportEvent[][]);
        const { groupMetadata, leagues } = this.createRowGroupMetadata(eventsWithUsers, competitionColors);
        return {
          events: eventsWithUsers,
          competitionColors,
          groupMetadata,
          leagues: [...leagues, ...CUSTOM_LEAGUE_COLORS],
        };
      })
    );
  }
  getSportEvents({ start, end } = DEFAULT_DATE_PERIOD): Observable<SportEventsWithMetadata> {
    const { startDate, endDate } = UtilsApp.formatStartEndForBackend(String(start), String(end));
    const urlFragment = `/api/planning/events/grouped/${startDate}/${endDate}`;
    return zip(this.http.get<SportEvent[][]>(environment.rootUrl + urlFragment), this.getColorsByDate({ start, end }), this.getColorsForCompetitions(), this.getUsers({ start, end })).pipe(
      map(([events, colors, competitionColors, users]) => {
        this.colors = colors;
        const formattedEvents = this.addPropsToEvents(events, colors);
        const eventsWithUsers = formattedEvents ? this.populateEventsWithAvailableUsers(formattedEvents, users) : ([] as SportEvent[][]);
        const { groupMetadata, leagues } = this.createRowGroupMetadata(eventsWithUsers, competitionColors);
        return {
          events: eventsWithUsers,
          competitionColors,
          groupMetadata,
          leagues: [...leagues, ...CUSTOM_LEAGUE_COLORS],
        };
      })
    );
  }

  updateCellColor(color: string, colIdent: number, eventId: number): void {
    const found = this.colors.find((c) => c.colIdent === colIdent && c.sportEventId === eventId);
    const data: ColorOptions = {
      colorIdent: color,
      colIdent: Number(colIdent),
      sportEventId: eventId,
    };
    if (found) {
      found.colorIdent = color;
    } else {
      this.colors.push(data);
    }
    this.http.post(environment.rootUrl + "/api/planning/colors", data).subscribe();
  }

  getFormats(): Observable<any> {
    if (!this.formats) {
      this.formats = new ReplaySubject<any>();
      this.http.get(environment.rootUrl + "/api/planning/formats").subscribe((r) => {
        this.formats.next(r);
      });
    }
    return this.formats;
  }

  getInfos(): Observable<any> {
    if (!this.infos) {
      this.infos = new ReplaySubject<any>();
      this.http.get(environment.rootUrl + "/api/planning/infos").subscribe((r) => {
        this.infos.next(r);
      });
    }
    return this.infos;
  }

  deleteEvent(event: SportEvent): Observable<any> {
    event.isDeleted = true;
    return this.update(event);
  }

  checkUserAvailabilityForRoleByDateAndSport(user: string, roleKey: string, event: SportEvent): boolean {
    return SportEventsSharedService.checkUserAvailabilityForRoleByDateAndSport(user, this.usersAvailability, roleKey, event);
  }

  getColorsForCompetitions(): Observable<any> {
    return this.http.get(environment.rootUrl + "/api/planning/colors/competitions");
  }

  getColorsByDate({ start, end } = DEFAULT_DATE_PERIOD): Observable<ColorOptions[]> {
    const startDate = UtilsApp.formatForBackend(String(start));
    const endDate = UtilsApp.formatForBackend(String(end));
    return this.http.get<ColorOptions[]>(environment.rootUrl + `/api/planning/colors/${startDate}/${endDate}`);
  }

  getSports(): Observable<SportDef[]> {
    if (!this.sports) {
      this.sports = new ReplaySubject<SportDef[]>();
      this.http.get<SportDef[]>(environment.rootUrl + "/api/planning/sports").subscribe((r) => {
        this.sports.next(r);
      });
    }
    return this.sports;
  }

  getCompetitions(sportId: number): Observable<Competition[]> {
    if (!this.competitions[sportId]) {
      this.competitions[sportId] = new ReplaySubject<Competition[]>();
      this.http.get<Competition[]>(environment.rootUrl + "/api/planning/competitions/" + sportId).subscribe((r) => {
        this.competitions[sportId].next(r);
      });
    }
    return this.competitions[sportId];
  }

  getTeams(sportId: number): Observable<Team[]> {
    if (!this.teamsBySportId[sportId]) {
      this.teamsBySportId[sportId] = new ReplaySubject<Team[]>();
      this.http.get<Team[]>(environment.rootUrl + "/api/planning/teams/" + sportId).subscribe((r) => {
        this.teamsBySportId[sportId].next(r);
      });
    }
    return this.teamsBySportId[sportId];
  }

  addUserAvailabilityForEventsByDate(user: string, except: SportEvent, events: SportEvent[][]) {
    // @ts-ignore
    const arr = SportEventsSharedService.getEventsUserPresentInByDate(user, except.dateToGetAvailableUsers, events.flat());
    if (arr.length) {
      return;
    }
    return SportEventsSharedService.addUserAvailabilityForEventsByDate(
      user,
      this.usersAvailability,
      except,
      // @ts-ignore
      events.flat()
    );
  }

  private createRowGroupMetadata(items: SportEvent[][], competitionColors: any): { groupMetadata: any; leagues: League[] } {
    const groupMetadata = {};
    let leagues: League[] = [];
    items.forEach((items) => {
      for (let i = 0; i < items.length; i++) {
        const rowData = items[i];
        let groupName;
        if (rowData.gameDay === null) {
          groupName = `${UtilsApp.toFormatDate(rowData.programStart)}${rowData.competitionName}`;
        } else {
          groupName = `${rowData.gameDay}${rowData.competitionName}`;
        }
        if (!rowData.fake) {
          addLeague(rowData, competitionColors);
        }
        items[i].groupName = groupName;
        if (i == 0) {
          groupMetadata[groupName] = { index: 0, size: 1 };
        } else {
          const previousRowData = items[i - 1];
          const previousRowGroupName = previousRowData.groupName;
          if (groupName === previousRowGroupName) {
            groupMetadata[groupName].size++;
          } else {
            groupMetadata[groupName] = { index: i, size: 1 };
          }
        }
      }
    });

    return { groupMetadata, leagues };

    function addLeague<T extends SportEvent>({ competitionIdent, competitionLabel }: T, competitionColors: any) {
      if (leagues.find((league) => league.competitionLabel === competitionLabel)) {
        return;
      }
      leagues = [
        ...leagues,
        {
          competitionIdent,
          competitionLabel,
          color: competitionColors[competitionIdent] || "aquablue",
        },
      ];
    }
  }

  // fixUsersIntersections(events: SportEvent[][], full: boolean, colors: ColorOptions[] = this.colors) {
  //   this.usersToEventsAssignerWorker.postMessage({events, colors});
  // }

  clearState(): void {
    this.usersAvailability = null;
    this.initWorker();
  }

  getUserDetails(user: string): Observable<any> {
    return this.http.get(environment.rootUrl + `/api/planning/statistics/${user}`);
  }
  getUserDetailsGrouped(user: string): Observable<any> {
    return this.http.get(environment.rootUrl + `/api/planning/statistics/grouped/${user}`);
  }

  private initWorker() {
    if (typeof Worker !== "undefined") {
      if (!this.usersToEventsAssignerWorker) {
        this.usersToEventsAssignerWorker = new Worker("../web-workers/events-user-assigner.worker", { type: "module" });
        fromEvent(this.usersToEventsAssignerWorker, "message").subscribe((msg) => {
          this.workerResponse.next(msg);
        });
      }
      this.workerResponse = new ReplaySubject(1);
      this.workerResponse$ = this.workerResponse.asObservable().pipe(pluck("data"));
    } else {
    }
  }

  getUsers({ start, end } = DEFAULT_DATE_PERIOD): Observable<UsersAvailability> {
    const startDate = UtilsApp.formatForBackend(String(start));
    const endDate = UtilsApp.formatForBackend(String(end));
    return (
      this.http
        .get<any>(environment.rootUrl + `/api/planning/users/${startDate}/${endDate}`)
        // return of(USERS_REPOSNSE)
        .pipe(
          map(mapUsersResponseToEventsUserRolesKeys),
          tap((data) => (this.usersAvailability = cloneDeep(data)))
        )
    );
  }

  private addPropsToEvents(events: SportEvent[][], colors: ColorOptions[]): SportEvent[][] {
    return events && events.length > 0
      ? events.map((groupe) => {
          return groupe.map((event) => {
            const coloredCells = colors.filter((option) => option.sportEventId === event.id);
            const formattedColors = {};
            coloredCells.forEach((option) => {
              formattedColors[option.colIdent] = option.colorIdent;
            });
            return {
              ...event,
              colors: formattedColors,
              dateFormatted: UtilsApp.toFormatDate(event.programStart, "dd.LL"),
              dateToGetAvailableUsers: UtilsApp.toFormatDate(event.programStart, "yy MM dd").replace(/ /g, ""),
            };
          });
        })
      : events;
  }

  private populateEventsWithAvailableUsers(events: SportEvent[][], users: UsersAvailability): SportEvent[][] {
    events.forEach((groupe) => {
      groupe.forEach((event) => {
        USER_ROLES_KEYS.forEach((roleKey) => {
          const availableUsers = get(users, `${event.dateToGetAvailableUsers}.${event.sportName}.${roleKey}`) || [];
          event[getAvailableUsersRoleKey(roleKey)] = availableUsers;
        });
      });
    });
    return events;
  }

  private groupEventsAndSort(groups: Array<SportEvent[]>) {
    groups.forEach((g, index) => g.unshift(new SportEvent()));
    groups[0] = groups[0] ? groups[0].slice(1, groups[0].length) : undefined;
    let flattened = flatten(groups);
    if (flattened && flattened.length && flattened[0] === undefined) {
      flattened = SportEvent[0];
    }
    return flattened;
  }

  getUsersByVPU(userId, vpu, role) {
    return this.http.get<any[]>(environment.rootUrl + `/api/planning/vpu/users/${userId}/${vpu}/${role}`);
  }

  saveVPU(id: number, vpu: string): Observable<VPUResponse> {
    return this.http.post<VPUResponse>(environment.rootUrl + `/api/planning/vpu`, {
      sportEventId: id,
      changedVpu: vpu,
    });
  }
}

export function mapUsersResponseToEventsUserRolesKeys(users: UsersAvailability): UsersAvailability {
  Object.values(users).forEach((competitionGroupByDate) => {
    // todo mocked
    // competitionGroupByDate['BBL'] = competitionGroupByDate['Eishockey'];

    Object.values(competitionGroupByDate).forEach((userGroupByCompetition) => {
      Object.entries(ROLES_DICTIONARY).forEach(([eventResponseKey, userResponseKey]) => {
        userGroupByCompetition[eventResponseKey] = userGroupByCompetition[userResponseKey];
        // if (userGroupByCompetition[eventResponseKey].length) {
        //   userGroupByCompetition[eventResponseKey].push('additional mocked user')
        // }
      });
    });
  });
  return users;
}

// key - sportevent response, value - users response
export const ROLES_DICTIONARY = {
  roleCommenter: "KOM",
  roleRegie: "Regie",
  roleEditor: "Producer:in Live",
  rolePresenter: "Moderation",
  roleExpert: "Expert:in",
  roleSBCProducer: "SBCProducer",
  roleMaz: "Social",
};

// select current season (01.07. - 30.06.)
const today = new Date();
const yearOffset = today.getMonth() + 1 < 7 ? -1 : 0;
const start = new Date("2000-07-01").setFullYear(today.getFullYear() + yearOffset);
const end = new Date("2000-06-30").setFullYear(today.getFullYear() + yearOffset + 1);

export const DEFAULT_DATE_PERIOD = {
  start: +start,
  end: +end,
};

// default period for dispo table
const startDispo = new Date();
// const startDispo = new Date().setMonth(new Date().getMonth() - 1);
// const endDispo = new Date().setMonth(new Date().getMonth() + 13);
const endDispo = new Date().setMonth(new Date().getMonth() + 1);
export const DEFAULT_DATE_PERIOD_DISPO = {
  start: +startDispo,
  end: +endDispo,
};

export const CUSTOM_LEAGUE_COLORS = [
  {
    competitionLabel: "grün",
    color: "rgb(102,255,102)",
    competitionIdent: null,
  },
  { competitionLabel: "rot", color: "red", competitionIdent: null },
  { competitionLabel: "magenta", color: "magenta", competitionIdent: null },
  { competitionLabel: "Farbe entfernen", color: "", competitionIdent: null },
];

export interface SportEventsWithMetadata {
  events: SportEvent[][];
  competitionColors?: any;
  groupMetadata: any;
  leagues: League[];
}
