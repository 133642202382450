import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable, of, zip} from 'rxjs';
import {cloneDeep, uniqWith} from 'lodash';
import {map, tap} from 'rxjs/operators';
import {DEFAULT_DATE_PERIOD, SportEventsService} from '../common/services/sport-events.service';
import {Utils as UtilsApp} from '../utils/Utils.app';


@Injectable({
  providedIn: 'root'
})
export class StatisticsOverviewService {

  constructor(
    private readonly http: HttpClient,
    private readonly sportEventService: SportEventsService,
  ) {
  }

  public getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(environment.rootUrl + '/api/dispositionAdmin/roles');
  }

  public getStatistics(role: Role, period: {
    start: number,
    end: number
  } = DEFAULT_DATE_PERIOD): Observable<any> {
    const dateStart = UtilsApp.formatForBackend(String(period.start));
    const dateEnd = UtilsApp.formatForBackend(String(period.end));
    return zip(
      this.http.get<Statistics>(environment.rootUrl + `/api/planning/statistics/${role}/${dateStart}/${dateEnd}`),
      this.sportEventService.getColorsForCompetitions()
      // this.http.get<any>('/assets/mock-json/statistics.json'),
      // this.http.get<any>('/assets/mock-json/competitions.json'),
    )
      .pipe(
        map(([data, colors]) => ({
          users: Object.entries(data.users).map(([name, data]) => ({
            ...data,
            name
          })),
          competitions: this.mapColorsToCompetitions(
            this.getAllCompetitions(data),
            colors,
          ),
          seasonBegin: data.seasonBegin
        })),
        tap(r => {
        })
      );
  }


  public updateUserDetails(value: string, data: UserData, competition: CompetitionData, role: Role, color?: COLORS) {
    const dataCopied = cloneDeep(data);
    const competitionName = competition.competitionName;
    data.competitionStatistics[competitionName] = {
      ...(data.competitionStatistics[competitionName] || {}),
      loading: true
    } as CompetitionData
    dataCopied.competitionStatistics[competitionName] = {
      competitionIdent: competition.competitionIdent,
      competitionName,
      sportName: '',
      ...(dataCopied.competitionStatistics[competitionName] || {
        userDetails: {
          planningCounter: 0,
          state: 0,
          planningTarget: null
        }
      })
    };
    const competitionStatistics = dataCopied.competitionStatistics[competitionName];
    const userDetails = competitionStatistics.userDetails;
    userDetails.planningTarget = value === null ? userDetails.planningTarget : Number(value);

    if (color) {
      userDetails.state = this.getStateByColor(color);
    }

    const body = {
      competitionIdent: competitionStatistics.competitionIdent,
      value: userDetails.planningTarget,
      state: userDetails.state,
      userName: dataCopied.userAbbrev
    };
    return this.updateSollValue(body, role)
      .pipe(
        tap(success => {
          data.competitionStatistics = dataCopied.competitionStatistics
        })
      );
  }

  public createUserDetails({competitionIdent, value, state, userName}, role: Role) {
    return this.http.put(environment.rootUrl + `/api/planning/statistics/targets/${userName}`, {
      Role: role,
      PlanningTarget: value,
      CompetitionIdent: competitionIdent,
      PlanningState: state
    });
  }

  public updateSollValue({competitionIdent, value, state, userName}, role: Role) {
    return this.http.post(environment.rootUrl + `/api/planning/statistics/targets/${userName}`, {
      Role: role,
      PlanningTarget: value,
      CompetitionIdent: competitionIdent,
      PlanningState: state
    });
  }

  private getAllCompetitions(data: Statistics) {
    const competitions = [];
    Object.entries(data.users).forEach(([, value]) => {
      Object.entries(value.competitionStatistics).forEach(([, competitionData]) => {
        competitions.push({
          ...competitionData,
          summary: {
            planningCounter: data.competitionPlanningNumbers[competitionData.competitionIdent].planningCounter,
            planningTarget: data.competitionPlanningNumbers[competitionData.competitionIdent].planningTarget
          },
          userDetails: undefined
        });
      });
    });
    return uniqWith(competitions, (o1, o2) => o1.competitionIdent === o2.competitionIdent);
  }

  private mapColorsToCompetitions(competitions: CompetitionData[], colors: string[]) {
    competitions.forEach(competition => competition.color = colors[competition.competitionIdent]);
    return competitions;
  }

  public getColorByState(state: number = 0) {
    return COLORS_MAPPINGS[state];
  }

  private getStateByColor(color: COLORS = 'transparent'): number {
    return Number(Object.entries(COLORS_MAPPINGS).find(([key, value]) => {
      return value === color;
    })[0]);
  }
}

export class Role extends String {
}

export class CompetitionData {
  summary?: {
    planningCounter: number,
    planningTarget: number
  };
  competitionIdent: number;
  competitionName: string;
  sportName: string;
  color?: string;
  loading?: boolean
  userDetails: {
    planningCounter: number;
    state: number;
    planningTarget: number;
  };
}

export class Statistics {
  competitionPlanningNumbers: {
    [key: number]: {
      planningCounter: number;
      planningTarget: number;
    }
  };
  users: {
    [key: string]: {
      competitionStatistics: {
        [key: string]: CompetitionData
      }
    }
  };

  [key: string]: any
}

export class UserData {
  competitionStatistics: {
    [key: string]: CompetitionData
  };
  name: string;
  planningCounterOverall: number;
  userNamePlanning: string;
  loginName: string;
  userAbbrev: string;
  loading?: boolean
}

export const COLORS_MAPPINGS = {
  0: 'transparent',
  1: 'green',
  2: 'red'
};

export type COLORS = 'transparent' | 'green' | 'red'
