import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EMPTY, Observable, forkJoin } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { Cost } from "../models/user-costs";
import { DEFAULT_DATE_PERIOD, SportEventsService, SportEventsWithMetadata } from "./sport-events.service";
import { ExcelExportService } from "./excel-export.service";
import { IUser } from "../models/user";

@Injectable()
export class CostService {
  private serviceDomain = "/api/costs";

  constructor(private httpClient: HttpClient, private sportEventsService: SportEventsService, private exportExcelService: ExcelExportService) {}

  getCostSummary({ start, end } = DEFAULT_DATE_PERIOD): Observable<SportEventsWithMetadata> {
    return this.sportEventsService.getSportCosts({ start, end });
  }
  getCostsByUser(username: string): Observable<Cost[]> {
    return forkJoin([this.getUserData(username), this.httpClient.get<Cost[]>(`${environment.rootUrl}${this.serviceDomain}/costs/${username}`)]).pipe(
      map(([user, costs]) => {
        if (!user.roles) return [];
        return user.roles.map((role) => {
          const foundCost = costs.find((cost) => cost.roleKey === role);
          return {
            id: foundCost?.id || null,
            userLoginName: user.loginName,
            roleKey: role,
            costs: foundCost?.costs || 0,
          };
        });
      })
    );
  }
  private getUserData(username: string): Observable<IUser> {
    return this.httpClient.get<IUser>(`${environment.rootUrl}/api/disposition/user/${username}`);
  }

  updateCost(cost: Cost): Observable<void | Cost> {
    if (cost.id == null) {
      delete cost.id;
      return this.httpClient.post<Cost>(`${environment.rootUrl}${this.serviceDomain}/cost`, cost).pipe(catchError((err) => EMPTY));
    } else {
      return this.httpClient.put<void>(`${environment.rootUrl}${this.serviceDomain}/cost/${cost.id}`, cost).pipe(catchError((err) => EMPTY));
    }
  }
  exportCostSummary({ start, end } = DEFAULT_DATE_PERIOD) {
    this.exportExcelService.exportToExcel({ start, end }, `${environment.rootUrl}${this.serviceDomain}/events/export`);
  }
}
