export class ColorUtils {
  static RGBToHex(rgb) {
    // Choose correct separator
    const sep = rgb.indexOf(",") > -1 ? "," : " ";
    // Turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb.substr(4).split(")")[0].split(sep);

    let r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);

    if (r.length == 1) {
      r = "0" + r;
    }
    if (g.length == 1) {
      g = "0" + g;
    }
    if (b.length == 1) {
      b = "0" + b;
    }

    return "#" + r + g + b;
  }

  static getTextColor(bgColor) {
    // bgColor = 'rgb(0,0,0)';
    if (!bgColor?.match(/^rgb/)) {
      return "#000000";
    }
    bgColor = ColorUtils.RGBToHex(bgColor);
    const whiteContrast = ColorUtils.getContrast(bgColor, "#ffffff");
    const blackContrast = ColorUtils.getContrast(bgColor, "#000000");

    return whiteContrast > blackContrast ? "#ffffff" : "#000000";
  }

  static getContrast(f, b) {
    const L1 = ColorUtils.getLuminance(f);
    const L2 = ColorUtils.getLuminance(b);
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
  }

  static getLuminance(hexColor) {
    return (
      0.2126 * ColorUtils.getsRGB(hexColor.substr(1, 2)) +
      0.7152 * ColorUtils.getsRGB(hexColor.substr(3, 2)) +
      0.0722 * ColorUtils.getsRGB(hexColor.substr(-2))
    );
  }

  static getsRGB(c) {
    return ColorUtils.getRGB(c) / 255 <= 0.03928
      ? ColorUtils.getRGB(c) / 255 / 12.92
      : Math.pow((ColorUtils.getRGB(c) / 255 + 0.055) / 1.055, 2.4);
  }

  static getRGB(c) {
    return parseInt(c, 16) || c;
  }
}
