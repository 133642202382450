import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { Subject } from "rxjs";
import {
  debounceTime,
  exhaustMap,
  filter,
  finalize,
  takeUntil,
  tap,
} from "rxjs/operators";
import { Cost } from "../common/models/user-costs";
import { AutoDestroyService } from "../common/services/autodestroy.service";
import { CostService } from "../common/services/cost.service";
import { isNumber } from "lodash";

export const COST_CONFIGURATION_SELECTOR = "app-cost-configuration";
@Component({
  selector: COST_CONFIGURATION_SELECTOR,
  templateUrl: "./cost-configuration.component.html",
  styleUrls: ["./cost-configuration.component.scss"],
  providers: [AutoDestroyService, CostService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CostConfigurationComponent implements OnInit {
  username: string = "";
  costs: Cost[] = [];
  startSkeleton: boolean = true;
  costChange$: Subject<Cost> = new Subject<Cost>();
  loadingStates: {
    [key: string]: boolean;
  } = {};
  constructor(
    @Inject("routeSvc") private routeSvc: any,
    private destroy$: AutoDestroyService,
    private costService: CostService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.username = this.routeSvc.getRouteParam("username");
    if (this.username) {
      this.getCosts();
      this.subscribeToCostChange();
    } else {
      location.href = "/#!/userlist";
    }
  }

  getCosts(): void {
    this.costService
      .getCostsByUser(this.username)
      .pipe(
        finalize(() => {
          this.startSkeleton = false;
          this.cdr.detectChanges();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((costs) => {
        this.costs = [...costs];
        this.costs.forEach((cost) => {
          this.loadingStates[cost.roleKey] = false;
        });
      });
  }
  subscribeToCostChange(): void {
    this.costChange$
      .pipe(
        debounceTime(1000),
        filter((cost) => isNumber(cost.costs)),
        tap((cost) => {
          this.loadingStates[cost.roleKey] = true;
          this.cdr.detectChanges();
        }),
        exhaustMap((cost) =>
          this.costService.updateCost(cost).pipe(
            finalize(() => {
              this.loadingStates[cost.roleKey] = false;
              this.cdr.detectChanges();
            })
          )
        ),
        takeUntil(this.destroy$)
      )
      .subscribe((res) => {
        if (res) {
          this.costs = [
            ...this.costs.map((cost) => {
              if (cost.roleKey === res.roleKey) {
                cost.id = res.id;
              }
              return cost;
            }),
          ];
          this.cdr.detectChanges();
        }
      });
  }
  back(){
    location.href = "/#!/userlist";
  }
}
