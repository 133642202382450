import {Component, Inject, OnInit} from '@angular/core';
import { CandyDate } from 'ng-zorro-antd';
// @ts-ignore
CandyDate.prototype.firstDayOfWeek = () => 1;

@Component({
  selector: 'app-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    @Inject('$rootScope') private $rootScope: any,
  ) { }

  ngOnInit(): void {
    this.$rootScope.showHeader = true;
  }

}
