import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { cloneDeep, get } from 'lodash';
import { Observable, zip } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ContentProductionEvent } from '../../common/models/content-production-events-page.models';
import { ColorOptions, UsersAvailability } from '../../common/models/sport-events-page.models';
import {
  CUSTOM_LEAGUE_COLORS,
  DEFAULT_DATE_PERIOD, League
} from '../../common/services/sport-events.service';
import { SportEventsSharedService } from '../../common/services/sport-events.shared.service';
import {
  AVAILABLE_USERS_ADDITIONAL_KEY,
  USER_ROLES_KEYS,
  USER_ROLES_KEYS_CONTENT,
  getAvailableUsersRoleKey
} from '../../constants/sport-event.constant';
import { Utils as UtilsApp } from '../../utils/Utils.app';
import { ExcelExportService } from 'src/app/common/services/excel-export.service';

@Injectable({
  providedIn: 'root'
})
export class ContentProductionService {

  constructor(
    private readonly http: HttpClient,
    private excelExportService: ExcelExportService,
    @Inject('appSvc') private appSvc: any,
  ) {
  }
  private usersAvailabilityContentProduction: UsersAvailability;
  private colors: ColorOptions[];

  static addUserAvailabilityForEventsByDate(
    user: string,
    users: UsersAvailability,
    except: ContentProductionEvent,
    events: ContentProductionEvent[]
  ) {
    if (ContentProductionService.getRolesUserPresentIn(except, user).length) {
      return;
    }

    return ContentProductionService.getEventsByDate(except.dateToGetAvailableUsers, events)
      .filter(event => event !== except)
      .forEach(event => {
        USER_ROLES_KEYS.forEach(roleKey => {
          const key = roleKey + AVAILABLE_USERS_ADDITIONAL_KEY;
          if (ContentProductionService.checkUserAvailabilityForRoleByDateAndSport(user, users, roleKey, event)) {
            event[key] = [...event[key], ...(user ? [user] : [])];
          }
        });
      });
  }

  static getRolesUserPresentIn(event: ContentProductionEvent, username: string): string[] {
    return USER_ROLES_KEYS.filter(roleKey => username && event[roleKey] === username);
  }

  static getEventsByDate(dayCode: string, items: ContentProductionEvent[]) {
    return items.filter(i => i.dateToGetAvailableUsers === dayCode);
  }

  static checkUserAvailabilityForRoleByDateAndSport(
    user: string,
    usersAvailability: UsersAvailability,
    roleKey: string,
    {sportName, dateToGetAvailableUsers}: ContentProductionEvent
  ): boolean {
    const users = get(usersAvailability, `${dateToGetAvailableUsers}.${sportName}.${roleKey}`) as any as string[] || [];
    return !!users.find(u => u === user);
  }

  getContentSportEvents({start, end} = DEFAULT_DATE_PERIOD): Observable<ContentProductionEventsWithMetadata> {
    const startDate = UtilsApp.formatForBackend(String(start));
    const endDate = UtilsApp.formatForBackend(String(end));

    return zip(
      this.http.get<ContentProductionEvent[][]>(environment.rootUrl + `/api/ContentProduction/events/grouped/${startDate}/${endDate}`),
      this.getColorsContentProductionByDate({start, end}),
      this.getUsersContentProduction({start, end}),
    )
      .pipe(
        map(([events, colors, users]) => {
          this.colors = colors;
          const formattedEvents = this.addPropsToEvents(events, colors);
          const eventsWithUsers = formattedEvents ? this.populateEventsWithAvailableUsers(formattedEvents, users) : [] as ContentProductionEvent[][];
          const {groupMetadata, leagues} = this.createRowGroupMetadata(eventsWithUsers);
            // this.fixUsersIntersections(eventsWithUsers, true, colors);

          return {
              events: eventsWithUsers,
              groupMetadata,
              leagues: [
                ...leagues,
                ...CUSTOM_LEAGUE_COLORS
              ]
            };
          },
        )
      );
  }

  getColorsContentProductionByDate({start, end} = DEFAULT_DATE_PERIOD): Observable<ColorOptions[]> {
    const startDate = UtilsApp.formatForBackend(String(start));
    const endDate = UtilsApp.formatForBackend(String(end));
    return this.http.get<ColorOptions[]>(environment.rootUrl + `/api/ContentProduction/colors/${startDate}/${endDate}`);
  }

  private getUsersContentProduction({start, end} = DEFAULT_DATE_PERIOD): Observable<UsersAvailability> {
    const startDate = UtilsApp.formatForBackend(String(start));
    const endDate = UtilsApp.formatForBackend(String(end));
    // return this.http.get<any>('/assets/mock-json/users.json')
    return this.http.get<any>(environment.rootUrl + `/api/ContentProduction/users/${startDate}/${endDate}`)
      .pipe(
        map(mapUsersResponseToEventsUserRolesKeysContent),
        tap(data => this.usersAvailabilityContentProduction = cloneDeep(data)),
      );
  }

  private addPropsToEvents(events: ContentProductionEvent[][], colors: ColorOptions[]): ContentProductionEvent[][] {
    return events && events.length > 0 ? events.map((groupe) => {
      return groupe.map(event => {
        const coloredCells = colors.filter(option => option.sportEventId === event.id);
        const formattedColors = {};
        coloredCells.forEach(option => {
          formattedColors[option.colIdent] = option.colorIdent;
        });
        return {
          ...event,
          colors: formattedColors,
          dateFormatted: UtilsApp.toFormatDate(event.programStart, 'dd.LL'),
          dateToGetAvailableUsers: UtilsApp.toFormatDate(event.programStart, 'yy MM dd').replace(/ /g, '')
        };
      });
    }) : events;
  }

  private populateEventsWithAvailableUsers(events: ContentProductionEvent[][], users: UsersAvailability): ContentProductionEvent[][] {
    events.forEach((groupe) => {
      groupe.forEach(event => {
        USER_ROLES_KEYS_CONTENT.forEach(roleKey => {
          const availableUsers = get(users, `${event.dateToGetAvailableUsers}.${event.sportName}.${roleKey}`) || [];
          event[getAvailableUsersRoleKey(roleKey)] = availableUsers;
        });
      });
    });
    return events;
  }

  private createRowGroupMetadata(items: ContentProductionEvent[][]): { groupMetadata: any, leagues: League[] } {
    const groupMetadata = {};
    let leagues: League[] = [];
    items.forEach((items) => {
      for (let i = 0; i < items.length; i++) {
        const rowData = items[i];
        let groupName;
        if (rowData.gameDay === null) {
          groupName = `${UtilsApp.toFormatDate(rowData.programStart)}${rowData.competitionName}`;
        } else {
          groupName = `${rowData.gameDay}${rowData.competitionName}`;
        }
        if (!rowData.fake) {
          addLeague(rowData);
        }
        items[i].groupName = groupName;
        if (i == 0) {
          groupMetadata[groupName] = {index: 0, size: 1};
        } else {
          const previousRowData = items[i - 1];
          const previousRowGroupName = previousRowData.groupName;
          if (groupName === previousRowGroupName) {
            groupMetadata[groupName].size++;
          } else {
            groupMetadata[groupName] = {index: i, size: 1};
          }
        }
      }
    });

    return {groupMetadata, leagues};


    function addLeague<T extends ContentProductionEvent>(
      {competitionIdent, competitionLabel}: T,
    ) {
      if (leagues.find(league => league.competitionLabel === competitionLabel)) {
        return;
      }
      leagues =
        [...leagues,
          {
            competitionIdent,
            competitionLabel,
          }
        ];
    }
  }

  addUserAvailabilityForEventsByDateContentProduction(user: string, except: ContentProductionEvent, events: ContentProductionEvent[][]) {
    // @ts-ignore
    const arr = SportEventsSharedService.getEventsUserPresentInByDate(user, except.dateToGetAvailableUsers, events.flat());
    if (arr.length) {
      return;
    }
    return ContentProductionService.addUserAvailabilityForEventsByDate(
      user,
      this.usersAvailabilityContentProduction,
      except,
      // @ts-ignore
      events.flat()
    );
  }

  update(event: ContentProductionEvent): Observable<any> {
    return this.http.put(environment.rootUrl + `/api/ContentProduction/events/planning/${event.id}`, ContentProductionEvent.serialize(event));
  }

  create(event: any) {
    return this.http.post(environment.rootUrl + `/api/ContentProduction/events/planning`, event);
  }

  clearState(): void {
    this.usersAvailabilityContentProduction = null;
  }

  getConfig() {
    return this.http.get<any>(environment.rootUrl + '/api/ContentProduction/configuration');
  }

  deleteEvent(event: ContentProductionEvent): Observable<any> {
    return this.http.delete(environment.rootUrl + `/api/ContentProduction/events/planning/${event.id}`);
  }

  updateCellColor(color: string, colIdent: number, eventId: number): void {
    const found = this.colors.find(c => c.colIdent === colIdent && c.sportEventId === eventId);
    const data: ColorOptions = {
      colorIdent: color,
      colIdent: Number(colIdent),
      sportEventId: eventId
    };
    if (found) {
      found.colorIdent = color;
    } else {
      this.colors.push(data);
    }
    this.http.post(environment.rootUrl + '/api/ContentProduction/colors', data).subscribe();
  }
  export({ start, end } = DEFAULT_DATE_PERIOD) {
    this.excelExportService.exportToExcel({ start, end }, `${environment.rootUrl}/api/ContentProduction/export`, true);
  }
}

const startDispo = new Date();
// const startDispo = new Date().setMonth(new Date().getMonth() - 1);
// const endDispo = new Date().setMonth(new Date().getMonth() + 13);
const endDispo = new Date().setMonth(new Date().getMonth() + 2);
export const DEFAULT_DATE_PERIOD_CONTENT_PRODUCTION = {
  start: +startDispo,
  end: +endDispo
};

export function mapUsersResponseToEventsUserRolesKeysContent(users: UsersAvailability): UsersAvailability {
  Object.values(users).forEach(competitionGroupByDate => {
    // todo mocked
    // competitionGroupByDate['BBL'] = competitionGroupByDate['Eishockey'];

    Object.values(competitionGroupByDate).forEach(userGroupByCompetition => {
      Object.entries(ROLES_DICTIONARY_CONTENT_PRODUCTION).forEach(([eventResponseKey, userResponseKey]) => {
        userGroupByCompetition[eventResponseKey] = userGroupByCompetition[userResponseKey];
        // if (userGroupByCompetition[eventResponseKey].length) {
        //   userGroupByCompetition[eventResponseKey].push('additional mocked user')
        // }
      });
    });
  });
  return users;
}

export const ROLES_DICTIONARY_CONTENT_PRODUCTION = {
  roleRegie: 'Producer:in',
  roleEditor: 'Social Editor:in',
  roleCommenter: 'Cutter:in',
  rolePresenter: 'Near Live Clipper:in',
  roleExpert: 'Dyn',
};

export interface ContentProductionEventsWithMetadata {
  events: ContentProductionEvent[][];
  competitionColors?: any;
  groupMetadata: any;
  leagues: League[];
}


