import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-date-selector',
    templateUrl: './date-selector.component.html',
    styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent {

    form = new FormGroup({
        dateStart: new FormControl('', [Validators.required]),
        dateEnd: new FormControl('', [Validators.required]),
    });

    constructor(
        public dialogRef: MatDialogRef<DateSelectorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        if (data)
            this.form.patchValue({
                dateStart: new Date(data.start),
                dateEnd: new Date(data.end)
            });
    }


    selectDate() {
        if (this.form.valid)
            this.dialogRef.close(this.form.getRawValue());
    }

}
