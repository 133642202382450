import "./prototypes/array-prototypes.ts";
import { BrowserModule } from "@angular/platform-browser";
import { APP_INITIALIZER, NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UserSelectComponent } from "./_components/user-select/user-select.component";
import { MatDialogModule } from "@angular/material/dialog";
import { EventCreatorFormComponent } from "./_components/event-creator-form/event-creator-form.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from "@angular/material/core";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { TableModule } from "primeng/table";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { DateSelectorComponent } from "./_components/date-selector/date-selector.component";
import { TableComponent } from "./dispo/table.component";
import { UpgradeModule } from "@angular/upgrade/static";
import {MultiSelectModule} from 'primeng/multiselect';

import localeDe from "@angular/common/locales/de";
import localeDeExtra from "@angular/common/locales/extra/de";
import { registerLocaleData } from "@angular/common";
import { AuthTokenInterceptor } from "./common/interceptors/auth-token.interceptor";
import { AuthService } from "./common/services/auth.service";
import { CalendarModule } from "primeng/calendar";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { environment } from "../environments/environment";
import { FitlerPipe } from "./common/pipes/fitler.pipe";
import { MY_DATE_FORMATS, MyDateAdapter } from "./utils/DatepickerAdapter";
import { UserHasPermissionsDirective } from "./common/directives/permissions.directive";
import { MatMenuModule } from "@angular/material/menu";
import { ModalService } from "./common/services/modal.service";
import { UserDetailsComponent } from "./_components/modals/user-details/user-details.component";
import { StatisticsOverviewComponent } from "./statistics-overview/statistics-overview.component";
import { EventsReactivationTableComponent } from "./_components/modals/events-reactivation-table/events-reactivation-table.component";
import { EditUsersAvailabilitiesComponent } from './availabilities/edit-users-availabilities/edit-users-availabilities.component';
import { NzDatePickerModule } from "ng-zorro-antd/date-picker";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NZ_I18N, en_US, de_DE, NZ_DATE_CONFIG } from "ng-zorro-antd/i18n";
import { HeaderComponent } from "./_components/header/header.component";
import { NzPageHeaderModule } from "ng-zorro-antd/page-header";
import {
  NzLayoutModule,
  NzMenuModule,
  NzAvatarModule,
  NzIconModule,
  NzCardModule,
  NzTagModule,
  NzSkeletonModule,
  NzSpinModule,
  NzTableModule,
  NzFormModule,
  NzInputModule,
  NzInputNumberModule,
  NzSelectModule,
  NzSwitchModule,
  NzMessageModule,
  NzAlertModule,
  NzBackTopModule,
  NzDropDownModule,
  NzEmptyModule,
  NzTimePickerModule,
  NzToolTipModule,
  NzRadioModule,
  NzPopoverModule,
  NzCollapseModule,
  NzPopconfirmModule,
  NzTabsModule,
} from "ng-zorro-antd";
import { AccountBookFill, AlertFill, AlertOutline } from "@ant-design/icons-angular/icons";
import { IconDefinition } from "@ant-design/icons-angular";
import { NzSpaceModule } from "ng-zorro-antd/space";
import { UsersListComponent } from "./usersAdministration/users-list/users-list.component";
import { NzDividerModule } from "ng-zorro-antd/divider";
import { OverviewComponent } from "./overview/overview/overview.component";
import { AppComponent } from "./app.component";
import { ContentProductionComponent } from "./сontent-production/content-production.component";
import { SendOutComponent } from "./send-out/send-out.component";
import { ActionSendEmailComponent } from "./send-out/components/action-send-email/action-send-email.component";
import { CostOverviewComponent } from "./cost-overview/cost-overview.component";
import { CostConfigurationComponent } from "./cost-configuration/cost-configuration.component";
import { JobStatusComponent } from "./send-out/components/job-status/job-status.component";

registerLocaleData(localeDe, localeDeExtra);

const icons: IconDefinition[] = [AccountBookFill, AlertOutline, AlertFill];

@NgModule({
  declarations: [
    TableComponent,
    ContentProductionComponent,
    UserSelectComponent,
    EventCreatorFormComponent,
    DateSelectorComponent,
    FitlerPipe,
    UserHasPermissionsDirective,
    UserDetailsComponent,
    StatisticsOverviewComponent,
    EventsReactivationTableComponent,
    EditUsersAvailabilitiesComponent,
    HeaderComponent,
    UsersListComponent,
    OverviewComponent,
    AppComponent,
    SendOutComponent,
    ActionSendEmailComponent,
    CostOverviewComponent,
    CostConfigurationComponent,
    JobStatusComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UpgradeModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    CalendarModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatSnackBarModule,
    MultiSelectModule,
    NzDatePickerModule,
    NzButtonModule,
    NzPageHeaderModule,
    NzLayoutModule,
    NzMenuModule,
    NzAvatarModule,
    NzIconModule.forRoot(icons),
    NzCardModule,
    NzSpaceModule,
    NzTagModule,
    NzSkeletonModule,
    NzSpinModule,
    NzTableModule,
    NzDividerModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    NzSwitchModule,
    NzMessageModule,
    NzAlertModule,
    NzBackTopModule,
    NzDropDownModule,
    NzEmptyModule,
    NzTimePickerModule,
    NzToolTipModule,
    NzRadioModule,
    NzInputNumberModule,
    NzPopoverModule,
    NzCollapseModule,
    NzPopconfirmModule,
    NzTabsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: ModalService) => () => ds.load(),
      deps: [ModalService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true,
    },
    {
      provide: "tokenSvc",
      useFactory: ($injector: any) => $injector.get("tokenSvc"),
      deps: ["$injector"],
    },
    {
      provide: "appSvc",
      useFactory: ($injector: any) => $injector.get("appSvc"),
      deps: ["$injector"],
    },
    {
      provide: "routeSvc",
      useFactory: ($injector: any) => $injector.get("routeSvc"),
      deps: ["$injector"],
    },
    {
      provide: "authSvc",
      useFactory: ($injector: any) => $injector.get("authSvc"),
      deps: ["$injector"],
    },
    {
      provide: "utilitySvc",
      useFactory: ($injector: any) => $injector.get("utilitySvc"),
      deps: ["$injector"],
    },
    { provide: MAT_DATE_LOCALE, useValue: "de-de" },
    AuthService,
    { provide: DateAdapter, useClass: MyDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: NZ_I18N, useValue: de_DE },
    {
      provide: NZ_DATE_CONFIG,
      useValue: {
        firstDayOfWeek: 1,
      },
    },
  ],
  entryComponents: [EventCreatorFormComponent, DateSelectorComponent, TableComponent, ContentProductionComponent, SendOutComponent, StatisticsOverviewComponent, EventsReactivationTableComponent, HeaderComponent],
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) {
    setRootUrl();
  }

  ngDoBootstrap() {
    this.upgrade.bootstrap(document.body, ["mmi.tp.disposition"], { strictDi: true });
  }
}

function setRootUrl() {
  window["ROOT_URL"] = environment.rootUrl;
}
