import {Component, OnInit} from '@angular/core';
import {COLORS, CompetitionData, Role, StatisticsOverviewService, UserData} from './statistics-overview.service';
import {DEFAULT_DATE_PERIOD, SportEventsService} from '../common/services/sport-events.service';
import {DateSelectorComponent} from '../_components/date-selector/date-selector.component';
import {debounceTime, filter, take, takeUntil} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {AutoDestroyService} from '../common/services/autodestroy.service';
import {Subject} from 'rxjs';
import {Utils as UtilsApp} from '../utils/Utils.app';

export const STATISTICS_OVERVIEW_SELECTOR = 'app-statistics-overview';

@Component({
    selector: STATISTICS_OVERVIEW_SELECTOR,
    templateUrl: './statistics-overview.component.html',
    styleUrls: ['./statistics-overview.component.scss'],
    providers: [AutoDestroyService]
})
export class StatisticsOverviewComponent implements OnInit {

    constructor(
        private readonly statisticsService: StatisticsOverviewService,
        private readonly dialog: MatDialog,
        private readonly destroy$: AutoDestroyService
    ) {
    }

    selectedCell: SelectedCell;
    editingPlanningTarget = '';
    users: UserData[] = [];
    roles: Role[] = [];
    selectedRole: Role = null;
    seasonBegin: number;
    competitions: CompetitionData[] = [];
    selectedDateRange = DEFAULT_DATE_PERIOD;
    private changeDebouncer = new Subject<{ value: string, competition: CompetitionData, data: UserData, color?: COLORS }>();

    getColorByState = this.statisticsService.getColorByState.bind(this.statisticsService);

    /* Other Functions */
    loading = true;

    ngOnInit(): void {
        this.statisticsService.getRoles().pipe(
            takeUntil(this.destroy$)
        ).subscribe(roles => {
            this.roles = roles;
            this.selectedRole = roles[0];
            this.loadData();
        });

        this.changeDebouncer.pipe(
            debounceTime(500)
        )
            .subscribe(({competition, value, data, color}) => {
                this.updateUserDetails(value, data, competition, color);
            });

    }

    roleSelected(role: Role) {
        this.selectedRole = role;
        this.loadData();
    }

    loadData(): void {
        this.loading = true;
        this.statisticsService.getStatistics(this.selectedRole, this.selectedDateRange)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(r => {
                this.users = [...r.users];
                this.competitions = r.competitions;
                this.seasonBegin = r.seasonBegin;
                this.loading = false;
            });
    }

    /* Custom Sort Event Functions */
    sortByNameEvent(event) {
        let target = this.getCompetitionHeaderTargetAndResetHeading(event);
        let reverseSort = this.getSortDirectionAndUpdateIcon(target);
        this.sortByName(reverseSort);
    }

    sortByCompetitionIst(competition: string, event) {
        let target = this.getCompetitionHeaderTargetAndResetHeading(event);
        let reverseSort = this.getSortDirectionAndUpdateIcon(target);
        this.sortByName();
        this.users.sort(function(a, b) {
            if (a === null || b === null) { return 0; }
            if (reverseSort) { b = [a, a = b][0]; } // swap variables
            return b.competitionStatistics[competition]?.userDetails.planningCounter -
                a.competitionStatistics[competition]?.userDetails.planningCounter;
        });
    }

    sortByCompetitionSoll(competition: string, event) {
        let target = this.getCompetitionHeaderTargetAndResetHeading(event);
        let reverseSort = this.getSortDirectionAndUpdateIcon(target);
        this.sortByName();
        this.users.sort(function(a, b) {
            if (a === null || b === null) { return 0; }
            if (reverseSort) { b = [a, a = b][0]; } // swap variables
            return b.competitionStatistics[competition]?.userDetails.planningTarget -
                a.competitionStatistics[competition]?.userDetails.planningTarget;
        });
    }

    sortByPlanningCounterOverall(event) {
        let target = this.getCompetitionHeaderTargetAndResetHeading(event);
        let reverseSort = this.getSortDirectionAndUpdateIcon(target);
        this.users.sort(function(a, b) {
            if (a === null || b === null) { return 0; }
            if (reverseSort) { b = [a, a = b][0]; } // swap variables
            return b.planningCounterOverall - a.planningCounterOverall;
        });
    }

    /* Custom Sort Helper Functions */
    sortByName(reverseSort = false) {
        this.users.sort(function(a, b) {
            if (a === null || b === null) { return 0; }
            if (reverseSort) { b = [a, a = b][0]; } // swap variables
            return a.userNamePlanning.localeCompare(b.userNamePlanning);
        });
    }

    getCompetitionHeaderTargetAndResetHeading(event) {
        let currentColumnHeader = event.target;
        while (!currentColumnHeader.classList.contains('mmi-custom-sort-header')) {
            currentColumnHeader = currentColumnHeader.parentNode;
        }

        // reset all column headers except for current target column
        Array.from(document.getElementsByClassName('mmi-custom-sort-icon')).forEach(element => {
            this.resetHeadingElement(currentColumnHeader, element);
        });

        return currentColumnHeader;
    }

    resetHeadingElement(currentColumnHeader, element) {
        // ignore clicked element
        if (element.parentNode === currentColumnHeader) { return; }

        // remove column header highlight
        element.parentNode.classList.remove('ui-sortable-column');
        element.parentNode.classList.remove('ui-state-highlight');

        // set sort icon to default
        let iconTarget = element.childNodes[0];
        iconTarget.classList.remove('pi-sort-amount-down');
        iconTarget.classList.remove('pi-sort-amount-up-alt');
        iconTarget.classList.add('pi-sort-alt');
    }

    getSortDirectionAndUpdateIcon(target) {
        let iconTarget = target.childNodes[1].childNodes[0];
        iconTarget.classList.remove('pi-sort-alt');

        let reverseSort = false;

        // detect sort direction by checking css classes, then change state
        if (iconTarget.classList.contains('pi-sort-amount-down')) {
            iconTarget.classList.remove('pi-sort-amount-down');
            iconTarget.classList.add('pi-sort-amount-up-alt');
            reverseSort = true;
        } else {
            iconTarget.classList.remove('pi-sort-amount-up-alt');
            iconTarget.classList.add('pi-sort-amount-down');
        }

        // highlight column header
        target.classList.add('ui-sortable-column');
        target.classList.add('ui-state-highlight');

        return reverseSort;
    }
    changeColor(data: UserData, competition: CompetitionData, color: COLORS) {
        this.changeDebouncer.next({
            value: null, data, competition, color
        });
    }

    sollChanged(data: UserData, competition: CompetitionData) {
        this.changeDebouncer.next({
            value: this.editingPlanningTarget, data, competition
        });
    }

    selectDate() {
        this.dialog.open(DateSelectorComponent, {
            width: '350px',
            data: this.selectedDateRange
        })
            .afterClosed()
            .pipe(
                filter(Boolean),
                take(1)
            )
            .pipe(takeUntil(this.destroy$))
            .subscribe(({dateStart, dateEnd}) => {
                this.selectedDateRange = {
                    start: dateStart,
                    end: dateEnd
                };
                this.loadData();
            });
    }

    onCellSelected(cell: SelectedCell) {
        const rowData = cell.data;
        const competition = cell.index;
        this.editingPlanningTarget = String(rowData.competitionStatistics[competition.competitionName]?.userDetails?.planningTarget);
        this.selectedCell = cell;
    }

    onCellSelectionOff() {
        switch (this.selectedCell.field) {
            case 'Soll':
                this.sollChanged(this.selectedCell.data, this.selectedCell.index);
                break;
            case 'Ist':
                break;
        }
        this.selectedCell = null;
    }

    onCellSelectionCancel() {
        this.selectedCell = null;
        this.editingPlanningTarget = '';
    }

    private updateUserDetails(value: string, data: UserData, competition: CompetitionData, color?: COLORS) {
        this.statisticsService.updateUserDetails(value, data, competition, this.selectedRole, color)
            .pipe(
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                if (!color) {
                    this.reCalculateSummary(competition);
                }
            });
    }

    private reCalculateSummary(competition: CompetitionData): void {
        const summary = this.users.reduce((accumulator: { planningTarget: number, planningCounter: number }, currentUser: UserData) => {
            if (!currentUser) { // null user is used to render table summary row, skip it
                return accumulator;
            }

            const competitionStatistic = currentUser.competitionStatistics[competition.competitionName];
            const userPlanningTarget = UtilsApp.toInteger(competitionStatistic.userDetails.planningTarget);
            const userPlanningCounter = UtilsApp.toInteger(competitionStatistic.userDetails.planningCounter);

            return {
                planningTarget: accumulator.planningTarget + userPlanningTarget,
                planningCounter: accumulator.planningCounter + userPlanningCounter
            };
        }, {planningTarget: 0, planningCounter: 0});

        competition.summary = {...summary};
    }
}

class SelectedCell {
    data: UserData;
    field: string;
    index: CompetitionData;
}
