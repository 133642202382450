import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NzMessageService } from "ng-zorro-antd";
import { takeUntil } from "rxjs/operators";
import { IUser } from "src/app/common/models/user";
import { AuthService } from "src/app/common/services/auth.service";
import { UserDetailsComponent } from "../../_components/modals/user-details/user-details.component";
import { AutoDestroyService } from "../../common/services/autodestroy.service";
import { DispositionAdminService, ROLE_TYPE } from "../../common/services/disposition-admin.service";

export const USERS_LIST_COMPONENT_SELECTOR = "app-users-list";

@Component({
  selector: USERS_LIST_COMPONENT_SELECTOR,
  templateUrl: "./users-list.component.html",
  styleUrls: ["./users-list.component.scss"],
  providers: [AutoDestroyService],
})
export class UsersListComponent implements OnInit {
  users: any;
  currentUserReal: any;
  roleDispo: any;
  roleContentProduction: any;
  sportsNames: any[];
  currentUser: IUser;
  abbrev: any;
  regionList = ["Nord", "Nordost", "Ost", "Süd", "Südwest", "West"];
  isLoading = false;
  startSkeleton = true;
  date: any;
  radioValue: any;
  userData$ = this.authService.userData$;
  ROLE_TYPE = ROLE_TYPE;
  form = new FormGroup({
    type: new FormControl(ROLE_TYPE.ALL),
  });
  constructor(
    @Inject("authSvc") private authSvc: any,
    @Inject("appSvc") private appSvc: any,
    private dispositionAdminService: DispositionAdminService,
    private authService: AuthService,
    private dialog: MatDialog,
    private message: NzMessageService,
    private destroy$: AutoDestroyService
  ) {}

  ngOnInit(): void {
    const loggedInUser = this.authSvc.GetLoggedInUser();
    if (!loggedInUser) {
      location.href = "/login";
      return;
    } else {
      this.initializeData();
    }

    this.form
      .get("type")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.refreshTable();
      });
  }

  showUserDetails(user: any) {
    this.dialog.open(UserDetailsComponent, {
      width: "600px",
      panelClass: "no-padding",
      data: {
        type: "user-details",
        user,
      },
    });
  }

  selectUser(user: IUser) {
    this.currentUser = { ...user };
    this.currentUserReal = user;
  }

  refreshTable() {
    this.dispositionAdminService
      .getUsers(this.form.get("type").value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        this.users = users;
      });
  }

  private initializeData() {
    this.dispositionAdminService
      .getUsers(this.form.get("type").value)
      .pipe(takeUntil(this.destroy$))
      .subscribe((users) => {
        this.startSkeleton = false;
        this.users = users;
        if (this.users && this.users.length > 0) {
          this.selectUser(this.users[0]);
        }
      });

    this.dispositionAdminService.getRoles(ROLE_TYPE.DISPO).subscribe((res) => {
      this.roleDispo = res;
    });

    this.dispositionAdminService.getRoles(ROLE_TYPE.CONTENT_PROD).subscribe((res) => {
      this.roleContentProduction = res;
    });

    // this.appSvc.GetRoleDefinitions()
    //   .then((roles) => {
    //     this.roleDefinitions = roles;
    //   });
    this.appSvc.GetSportsDefinitions().then((sportDefs) => {
      this.sportsNames = this.getDistinctSports(sportDefs);
    });
  }

  private getDistinctSports(sports) {
    return Object.keys(sports);
  }

  activateUser(user, abbrev) {
    if (!abbrev || abbrev.length < 2) {
      return;
    }
    const userCopy = { ...user };
    userCopy.abbrev = abbrev;
    userCopy.accessAllowed = true;
    this.appSvc.UpdateUser(userCopy).then(() => {
      user.abbrev = abbrev;
      user.accessAllowed = true;
      this.currentUserReal.abbrev = abbrev;
      this.currentUserReal.accessAllowed = true;
    });
  }

  saveUser() {
    this.isLoading = true;
    this.appSvc.UpdateUser(this.currentUser).then(
      (res) => {
        this.isLoading = false;
        this.message.create("success", `${this.currentUser.loginName} - Benutzer wurde gespeichert`);
        this.refreshTable();
      },
      (result) => {
        this.message.create("error", `Fehler - ${result.data.status}: ${result.data.title}`);
        this.isLoading = false;
      }
    );
  }

  handleChangeRole(checked: boolean, role: any) {
    if (!this.currentUser.roles) {
      this.currentUser.roles = [];
    }
    if (checked) {
      this.currentUser.roles.push(role);
    } else {
      this.currentUser.roles = this.currentUser.roles?.filter((t) => t !== role);
    }
  }

  handleChangeSports(checked: boolean, sport: any) {
    if (!this.currentUser.sports) {
      this.currentUser.sports = [];
    }

    if (checked) {
      this.currentUser.sports.push(sport);
    } else {
      this.currentUser.sports = this.currentUser.sports.filter((t) => t !== sport);
    }
  }

  changeHasDispoAccess(key, value) {
    if (!value) {
      this.currentUser.hasDispoWriteAccess = false;
    }
  }
  navigateToCostConfiguration() {
    location.href = `/#!/cost-configuration/${this.currentUser.loginName}`;
  }
}
