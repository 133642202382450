import {trigger} from '@angular/animations';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatDialog} from '@angular/material/dialog';
import {MatSelect} from '@angular/material/select';
import {cloneDeep} from 'lodash';
import {NzDatePickerComponent, NzMessageService} from 'ng-zorro-antd';
import {fromEvent, interval, merge, Observable, Subject, Subscription,} from 'rxjs';
import {debounceTime, filter, take, takeUntil} from 'rxjs/operators';
import {DateSelectorComponent} from '../_components/date-selector/date-selector.component';
import {EventCreatorFormComponent} from '../_components/event-creator-form/event-creator-form.component';
import {
  EventsReactivationTableComponent
} from '../_components/modals/events-reactivation-table/events-reactivation-table.component';
import {SportEvent} from '../common/models/sport-events-page.models';
import {AuthService} from '../common/services/auth.service';
import {AutoDestroyService} from '../common/services/autodestroy.service';
import {
  DEFAULT_DATE_PERIOD,
  DEFAULT_DATE_PERIOD_DISPO,
  League,
  ROLES_DICTIONARY,
  SportEventsService,
  VPUResponse,
} from '../common/services/sport-events.service';
import {
  AVAILABLE_USERS_ADDITIONAL_KEY,
  DEFAULT_USER,
  USER_ROLE_COLUMN_STARTING_INDEX,
  USER_ROLES_KEYS,
  USERS_ERROR_KEY,
} from '../constants/sport-event.constant';
import {ColorUtils} from '../utils/ColorUtils';
import {ScrollbarUtils} from '../utils/ScrollbarUtils';

export const TABLE_COMPONENT_SELECTOR = 'app-table-component';

@Component({
  animations: [trigger('', [])],
  selector: TABLE_COMPONENT_SELECTOR,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [AutoDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent implements AfterContentInit, OnInit, AfterViewInit {
  constructor(
    private readonly sportEventService: SportEventsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly destroy$: AutoDestroyService,
    private readonly elRef: ElementRef,
    private message: NzMessageService
  ) {
  }

  private defaultOptionVPU = '';
  private currentOptions: any[];
  private loadingVPU = false;
  private loadingGetVPU = false;
  private infos: any;

  @ViewChild('table', {static: true}) table: ElementRef<HTMLElement>;
  @ViewChild('header', {static: true}) header: ElementRef<HTMLElement>;
  @ViewChild('autocompleteTrigger', {static: false})
  autocomplete: MatAutocompleteTrigger;
  @ViewChild('select', {static: false}) select: MatSelect;

  @ViewChild('emailsDatePicker') emailsDatePicker!: NzDatePickerComponent;

  saveEventDebouncer = new Subject<SportEvent>();
  formats: { value: string; viewValue: string }[] = [];
  AVAILABLE_USERS_ADDITIONAL_KEY = AVAILABLE_USERS_ADDITIONAL_KEY;
  DEFAULT_USER = DEFAULT_USER;
  USER_ROLES_KEYS = USER_ROLES_KEYS;
  USER_ROLE_COLUMN_STARTING_INDEX = USER_ROLE_COLUMN_STARTING_INDEX;
  selectedCell: SelectedCell;
  tableHeight = '800px';
  editable: boolean;
  rowGroupMetadata: { [key: string]: { index: number; size: number } };
  leagues: League[] = [];
  leaguesCache: League[] = [];
  events: SportEvent[][] = [];
  selectedLeague: string;
  selectedDateRange = DEFAULT_DATE_PERIOD_DISPO;
  competitionColors: { [key: string]: string } = {};
  loading: boolean;
  startSkeleton = true;
  updateInterval: Subscription;
  additionalEditingEvent: SportEvent = undefined;
  userData$ = this.authService.userData$;
  form = new FormGroup({
    rangePicker: new FormControl('', [Validators.required]),
    rangePicker2: new FormControl('', [Validators.required]),
  });
  private optionsVPU: any[] = ['Loading...'];

  // private removeUserAvailabilityForEventsByDate(user: string, except: SportEvent): void {
  //   SportEventsSharedService.removeUserAvailabilityForEventsByDate(user, this.events, except.dateToGetAvailableUsers, except);
  // }
  isLoading = true;
  ranges1 = {
    'Aktuelle Saison': [DEFAULT_DATE_PERIOD.start, DEFAULT_DATE_PERIOD.end],
  };
  ranges2 = {
    'Emails verschicken': [DEFAULT_DATE_PERIOD.start, DEFAULT_DATE_PERIOD.end],
  };
  selectModel: any;
  scrolled = false;

  USERS_ERROR_KEY = USERS_ERROR_KEY;
  ranges2Open = false;
  getTextColor = (color: any) => ColorUtils.getTextColor(color);

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 20;
  }

  ngOnInit() {
    this.setDefaultDateRange();

    this.form
      .get('rangePicker')
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((date) => {
        this.selectedDateRange = {
          start: +date[0],
          end: +date[1],
        };
        if (this.loading) {
          this.loading = false;
        }
        this.initDatasource();
      });

    this.initListeners();
    // TODO: uncomment auto updater
    // this.updateInterval = this.subscribeAutoUpdater();
    this.initDatasource(true);
  }

  ngAfterContentInit(): void {
    scrollBarHeight = ScrollbarUtils.getScrollBarWidth();
    this.initResizeListener();
    this.getDefaultVPULabel();
  }

  ngOnDestroy(): void {
    this.sportEventService.clearState();
    // this.updateInterval.unsubscribe();
  }

  subscribeAutoUpdater(): Subscription {
    // reload data every 30 seconds to improve UX for multiple users in parallel
    return interval(30000).subscribe((val) => this.initDatasource(true));
  }

  saveEvent(event: SportEvent) {
    this.loading = true;
    this.sportEventService
      .update(event)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading = false;
        this.initDatasource(true);
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit() {
  }

  export() {
    this.sportEventService.exportToExcel(this.selectedDateRange);
  }

  undoDeletion(event: SportEvent) {
    this.loading = true;
    this.sportEventService
      .update({
        ...event,
        isDeleted: false,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.initDatasource();
      });
  }

  deleteEvent(event: SportEvent) {
    if (event.isDeleted) {
      return;
    }
    this.loading = true;
    this.sportEventService
      .deleteEvent(event)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading = false;
        this.initDatasource();
      });
  }

  selectFullSeason() {
    this.selectedDateRange = {
      start: +DEFAULT_DATE_PERIOD.start,
      end: +DEFAULT_DATE_PERIOD.end,
    };
    this.initDatasource();
  }

  selectDate() {
    this.dialog
      .open(DateSelectorComponent, {
        width: '350px',
        data: this.selectedDateRange,
      })
      .afterClosed()
      .pipe(filter(Boolean), take(1))
      .pipe(takeUntil(this.destroy$))

      .subscribe(({dateStart, dateEnd}) => {
        this.selectedDateRange = {
          start: +dateStart,
          end: +dateEnd,
        };
        if (this.loading) {
          this.loading = false;
        }
        this.initDatasource();
      });
  }

  showDeleted() {
    this.dialog
      .open(EventsReactivationTableComponent, {
        width: '98ww',
        panelClass: 'no-padding',
        data: {
          isShowingDeleted: true,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.initDatasource();
      });
  }

  editEvent(event: SportEvent): void {
    this.openEditingModal(event)
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(() => {
        this.initDatasource();
      });
  }

  navigateToStatistics(): void {
    location.href = '/#!/statistics-overview';
  }

  navigateToCostOverview(): void {
    location.href = '/#!/cost-overview';
  }

  createNewEvent(): void {
    this.openEditingModal()
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(() => {
        this.initDatasource();
      });
  }

  onCellSelectionOff() {
    const {field, data} = this.selectedCell;
    if (['6', '7'].includes(field) && !this.loading) {
      // 6 === format field
      // 12 === Gast field
      this.saveEvent(data);
    }
    this.selectedCell = null;
    this.leagues = this.leaguesCache;
    this.cdr.detectChanges();
  }

  onCellSelected(data: SelectedCell) {
    this.selectedCell = data;
    this.selectedLeague = data.data.competitionName;
    this.leagues = this.leagues.filter(
      (league) =>
        league.competitionIdent === null ||
        league.competitionLabel === data.data.competitionLabel
    );
    setTimeout(() => {
      if (this.autocomplete) {
        this.autocomplete.openPanel();
      }
      if (this.select) {
        this.select.open();
      }
      // this.header.nativeElement.dispatchEvent(new Event('resize'));
    });
    this.cdr.detectChanges();
  }

  getColorButtonBackgroundColor(competitionLabel: string) {
    const color = this.leagues.filter(
      (l) => l.competitionLabel === competitionLabel
    );
    if (color.length === 1) {
      return color[0].color;
    }
    return 'grey';
  }

  colorButtonEnabled(competitionLabel: string) {
    if (
      this.leagues.filter((l) => l.competitionLabel === competitionLabel)
        .length === 1
    ) {
      return true;
    }
    return false;
  }

  applyColor($event, colorCode: string, buttonEnabled: boolean) {
    $event.stopPropagation();
    if (!this.selectedCell || !buttonEnabled) {
      return;
    }
    this.selectedCell.data.colors[this.selectedCell.field] = colorCode;
    this.sportEventService.updateCellColor(
      colorCode,
      this.selectedCell.field,
      this.selectedCell.data.id
    );
    // TODO: Fix workaround for additional roles
    if (this.selectedCell.field === 13) {
      // setTimeout(() => {
      this.initDatasource();
      // }, 250);
    }
  }

  eventUserChanged(newUserName: string, event: SportEvent, roleKey: string) {
    const oldUserName = event[roleKey];
    event[roleKey] = newUserName;
    if (oldUserName === newUserName) {
      return;
    }
    if (oldUserName !== DEFAULT_USER) {
      this.addUserAvailabilityForEventsByDate(oldUserName, event);
    }

    this.saveEvent(event);
    this.cdr.detectChanges();
  }

  roleAdditionalsChanged(event: SportEvent): void {
    this.saveEvent(event);
    this.cdr.detectChanges();
  }

  roleAdditionalsInputChanged(event: SportEvent): void {
    this.additionalEditingEvent = event;
  }

  trackBy(index, item: SportEvent) {
    return item.id;
  }

  getUserRoleBgColor(event: SportEvent, roleValue, index) {
    return roleValue === DEFAULT_USER
      ? 'red'
      : event.colors[index + USER_ROLE_COLUMN_STARTING_INDEX];
  }

  private openEditingModal(event?: SportEvent): Observable<any> {
    return this.dialog
      .open(EventCreatorFormComponent, {
        width: '450px',
        panelClass: 'no-padding',
        data: {
          event,
        },
      })
      .afterClosed();
  }

  private initResizeListener() {
    const interval$ = interval(200).pipe(take(20));
    merge(fromEvent(window, 'resize'), interval$)
      .pipe(debounceTime(100))

      .pipe(takeUntil(this.destroy$))

      .subscribe(() => this.resizeTable());
  }

  private resizeTable() {
    const {top} = this.elRef.nativeElement.getBoundingClientRect();
    const ww = window.innerWidth;
    this.tableHeight = `${
      window.innerHeight - 30 - top - 5 - (ww < 1200 ? scrollBarHeight : 0)
    }px`; // -72
    this.cdr.detectChanges();
  }

  private addUserAvailabilityForEventsByDate(user: string, except: SportEvent) {
    this.sportEventService.addUserAvailabilityForEventsByDate(
      user,
      except,
      this.events
    );
  }

  private initListeners() {
    this.saveEventDebouncer
      .pipe(debounceTime(500))
      .subscribe((event) => this.saveEvent(event));

    // this.sportEventService.workerResponse$
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe(({events, full}) => {
    //
    //
    //         if(full){
    //             this.events = events;
    //         } else {
    //             events.forEach(event => {
    //                 const foundIndex = this.events.findIndex(e => e.id === event.id);
    //                 this.replaceAddtionalsWithActiveInput(event);
    //                 this.events[foundIndex] = event;
    //             });
    //         }
    //         this.cdr.detectChanges();
    //         // hack for changing additionals field while reloading and stuff
    //
    //         this.unsetActiveAdditionalEditingEvent();
    //     });
  }

  private unsetActiveAdditionalEditingEvent() {
    if (!this.selectedCell || this.selectedCell?.field !== 13) {
      setTimeout(() => {
        if (!this.selectedCell || this.selectedCell?.field !== 13) {
          this.additionalEditingEvent = undefined;
        }
      }, 500);
    }
  }

  private initDatasource(forceInit: boolean = false) {
    if (this.loading && !forceInit) {
      return;
    }
    this.loading = true;

    // TODO: uncomment auto updater
    // reset update interval
    // this.updateInterval.unsubscribe();
    // this.updateInterval = this.subscribeAutoUpdater();

    this.cdr.detectChanges();

    this.saveEventDebouncer
      .pipe(debounceTime(500))
      .subscribe((event) => this.saveEvent(event));

    this.authService
      .hasWritePermission()
      .pipe(takeUntil(this.destroy$))
      .subscribe((permission) => {
        this.editable = permission;
      });

    this.sportEventService
      .getFormats()
      .pipe(takeUntil(this.destroy$))
      .subscribe((formats) => {
        this.formats = formats
          .filter(Boolean)
          .map((format) => ({value: format, viewValue: format}));
      });

    this.sportEventService
      .getInfos()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.infos = res;
      });

    //  TODO: it's for testing
    // this.selectedDateRange.start = 1640988000000;
    // this.selectedDateRange.end = 1641988000000;
    this.sportEventService
      .getSportEvents(this.selectedDateRange)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({events, competitionColors, groupMetadata, leagues}) => {
        if (!this.loading) {
          return;
        } // discard result
        this.competitionColors = competitionColors;
        this.events = events;

        const findItem = this.events
          .flat()
          .find((item) => item.id === this.selectedCell?.data.id);

        if (this.selectedCell?.data && findItem) {
          this.selectedCell.data = findItem;
        }

        this.events = events.map((groupe) => {
          return groupe.map((e) => {
            this.replaceAddtionalsWithActiveInput(e);
            return e;
          });
        });
        this.rowGroupMetadata = groupMetadata;
        this.leaguesCache = cloneDeep(leagues);
        if (this.leagues.length === 0) {
          this.leagues = leagues;
        }
        this.loading = false;
        this.startSkeleton = false;
        this.resizeTable();
        this.unsetActiveAdditionalEditingEvent();
      });
  }

  private replaceAddtionalsWithActiveInput(event: SportEvent) {
    // TODO what if other user edits it after I edited it, then i would not know
    if (event.id === this.additionalEditingEvent?.id) {
      event.roleAdditionals = this.additionalEditingEvent.roleAdditionals;
    }
  }

  private setDefaultDateRange() {
    // TODO: uncomment
    // this.selectedDateRange = {
    //   start: +DEFAULT_DATE_PERIOD.start,
    //   end: +DEFAULT_DATE_PERIOD.end
    // };

    this.form
      .get('rangePicker')
      .setValue([this.selectedDateRange.start, this.selectedDateRange.end]);
  }

  changeVPU(vpu: any, event: SportEvent) {
    this.sportEventService
      .saveVPU(event.id, vpu)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.setErrors(res, event);
        this.cdr.detectChanges();
      });
    // this.saveEvent(event);
  }

  openChanged($event: boolean, sportEvent) {
    this.loadingGetVPU = true;
    this.sportEventService.getVPU(sportEvent.id).subscribe((res) => {
      this.optionsVPU = res;
      this.loadingGetVPU = false;
      this.cdr.detectChanges();
    });
  }

  private getDefaultVPULabel() {
    this.sportEventService.getVPUDefault().subscribe((res) => {
      this.defaultOptionVPU = res;
    });
  }

  openChangedUser($event: boolean, rowData: SportEvent, roleKey: string) {  
    this.loadingVPU = true;
    roleKey = ROLES_DICTIONARY[roleKey];
    if (rowData.vpu) {
      this.sportEventService
        .getUsersByVPU(rowData.id, rowData.vpu, roleKey)
        .subscribe((res) => {
          this.currentOptions = res;
          this.loadingVPU = false;
          this.cdr.detectChanges();
        });
    }
  }

  private setMaxLength() {
    const element = document.querySelector('mat-select-autocomplete');
    element.addEventListener('click', () => {
      const input = document.querySelector('.box-search input[type=text]');
      if (input) {
        input.setAttribute('maxlength', '4');
      }
    });
  }

  openAutocomplete() {
  }

  private setErrors(res: VPUResponse, event) {
    Object.entries(ROLES_DICTIONARY).forEach(([key, value]) => {
      event[key + USERS_ERROR_KEY] = null;
    });

    if (!res.hasErrors) {
      return;
    }

    res.roleErrors.map((error) => {
      let a;
      Object.entries(ROLES_DICTIONARY).forEach(([key, value]) => {
        if (value === error.role) {
          a = key;
        }
      });
      event[a + USERS_ERROR_KEY] = error.validationError;
    });
  }

  openEmailsDateRangePicker() {
    this.ranges2Open = true;
  }

  validationInfo(rowData) {
    if (rowData.info.length > 20) {
      rowData.info = rowData.info.slice(0, -1);
      return;
    }
  }
}

function getEventsByDate(dayCode: string, items: SportEvent[]) {
  return items.filter((i) => i.dateToGetAvailableUsers === dayCode);
}

class SelectedCell {
  data: SportEvent;
  field; // TODO: sometimes string or number (was string)
}

let scrollBarHeight = 0;
