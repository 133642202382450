import { trigger } from "@angular/animations";
import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { cloneDeep } from "lodash";
import { NzMessageService } from "ng-zorro-antd";
import { Observable, Subject, Subscription, fromEvent, interval, merge } from "rxjs";
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { DateSelectorComponent } from "../_components/date-selector/date-selector.component";
import { EventCreatorFormComponent } from "../_components/event-creator-form/event-creator-form.component";
import { EventsReactivationTableComponent } from "../_components/modals/events-reactivation-table/events-reactivation-table.component";
import { ContentProductionEvent } from "../common/models/content-production-events-page.models";
import { AuthService } from "../common/services/auth.service";
import { AutoDestroyService } from "../common/services/autodestroy.service";
import { DEFAULT_DATE_PERIOD, League, ROLES_DICTIONARY, VPUResponse } from "../common/services/sport-events.service";
import { AVAILABLE_USERS_ADDITIONAL_KEY, DEFAULT_USER, USERS_ERROR_KEY, USER_ROLES_KEYS_CONTENT, USER_ROLE_COLUMN_STARTING_INDEX_CONTENT } from "../constants/sport-event.constant";
import { ScrollbarUtils } from "../utils/ScrollbarUtils";
import { ContentProductionService, DEFAULT_DATE_PERIOD_CONTENT_PRODUCTION } from "./services/content-production.service";

export const CONTENT_COMPONENT_SELECTOR = "app-content-production-component";

@Component({
  animations: [trigger("", [])],
  selector: CONTENT_COMPONENT_SELECTOR,
  templateUrl: "./content-production.component.html",
  styleUrls: ["./content-production.component.scss"],
  providers: [AutoDestroyService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentProductionComponent implements AfterContentInit, OnInit, AfterViewInit {
  private config: {
    contentLocations: string[];
    contentTypes: string[];
    infos: string[];
    sports: {
      abbreviation: string;
      color: string;
      externalId: number;
      icon: string;
      id: number;
      shortName: string;
      sportNameExternal: string;
      sportNameInternal: string;
    }[];
  };

  constructor(
    private readonly contentProductionService: ContentProductionService,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService,
    private readonly dialog: MatDialog,
    private readonly destroy$: AutoDestroyService,
    private readonly elRef: ElementRef,
    private message: NzMessageService
  ) {}

  private defaultOptionVPU = "";
  private currentOptions: any[];
  private loadingVPU = false;
  private loadingGetVPU = false;

  @ViewChild("table", { static: true }) table: ElementRef<HTMLElement>;
  @ViewChild("header", { static: true }) header: ElementRef<HTMLElement>;
  @ViewChild("autocompleteTrigger", { static: false })
  autocomplete: MatAutocompleteTrigger;
  @ViewChild("select", { static: false }) select: MatSelect;

  saveEventDebouncer = new Subject<ContentProductionEvent>();
  AVAILABLE_USERS_ADDITIONAL_KEY = AVAILABLE_USERS_ADDITIONAL_KEY;
  DEFAULT_USER = DEFAULT_USER;
  USER_ROLES_KEYS_CONTENT = USER_ROLES_KEYS_CONTENT;
  USER_ROLE_COLUMN_STARTING_INDEX_CONTENT = USER_ROLE_COLUMN_STARTING_INDEX_CONTENT;
  selectedCell: SelectedCell;
  tableHeight = "800px";
  editable = true;
  rowGroupMetadata: { [key: string]: { index: number; size: number } };
  leagues: League[] = [];
  leaguesCache: League[] = [];
  events: ContentProductionEvent[][] = [];
  selectedLeague: string;
  selectedDateRange = DEFAULT_DATE_PERIOD_CONTENT_PRODUCTION;
  competitionColors: { [key: string]: string } = {};
  loading: boolean;
  startSkeleton = true;
  updateInterval: Subscription;

  additionalEditingEvent: ContentProductionEvent = undefined;

  form = new FormGroup({
    rangePicker: new FormControl("", [Validators.required]),
  });
  private optionsVPU: any[] = ["Loading..."];
  private optionsSports: any[] = [];

  isLoading = true;
  ranges1 = {
    "Aktuelle Saison": [DEFAULT_DATE_PERIOD.start, DEFAULT_DATE_PERIOD.end],
  };
  selectModel: any;
  scrolled = false;
  USERS_ERROR_KEY = USERS_ERROR_KEY;
  contentProductionInfoChange$: Subject<ContentProductionEvent> = new Subject<ContentProductionEvent>();
  @HostListener("window:scroll", [])
  onWindowScroll() {
    this.scrolled = window.scrollY > 20;
  }

  ngOnInit() {
    this.setDefaultDateRange();
    // this.getSportOptions();
    this.getConfig();

    this.form
      .get("rangePicker")
      .valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe((date) => {
        this.selectedDateRange = {
          start: +date[0],
          end: +date[1],
        };
        if (this.loading) {
          this.loading = false;
        }
        this.initDatasource();
      });

    this.initListeners();
    // TODO: uncomment auto updater
    // this.updateInterval = this.subscribeAutoUpdater();
    this.initDatasource(true);
    this.subscribeToContentProductionInfoChange();
  }

  ngAfterContentInit(): void {
    scrollBarHeight = ScrollbarUtils.getScrollBarWidth();
    this.initResizeListener();
  }

  ngOnDestroy(): void {
    this.contentProductionService.clearState();
    // this.updateInterval.unsubscribe();
  }

  subscribeAutoUpdater(): Subscription {
    // reload data every 30 seconds to improve UX for multiple users in parallel
    return interval(30000).subscribe((val) => this.initDatasource(true));
  }
  subscribeToContentProductionInfoChange(): void {
    this.contentProductionInfoChange$
      .pipe(
        debounceTime(1200),
        tap(() => (this.loading = true)),
        map((event) => {
          event.sportId = event.sport.id;
          return event;
        }),
        switchMap((event) => this.contentProductionService.update(event)),
        takeUntil(this.destroy$)
      )
      .subscribe(
        () => {
          this.loading = false;
          this.initDatasource(true);
          this.cdr.detectChanges();
        },
        (error) => {
          this.message.error(error.statusText);
          this.loading = false;
        }
      );
  }

  saveEndTime(event: ContentProductionEvent) {
    const startDate = new Date(event.programStart);

    if (event.programEnd) {
      const endDate = new Date(event.programEnd);
      if (endDate.getDate() < startDate.getDate()) {
        endDate.setDate(startDate.getDate());
        event.programEnd = endDate.toUTCString();
      }
    }
    this.saveContentProductionEvent(event);
  }

  saveContentProductionEvent(event: ContentProductionEvent) {
    event.sportId = event.sport.id;
    if (!this.areEventTimesValid(event.programStart, event.programEnd)) {
      this.message.error("Event Startzeit darf nicht leer sein und Ende > Start");
      event.programEnd = null;
      this.cdr.detectChanges();
      return;
    }
    this.loading = true;
    this.contentProductionService
      .update(event)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.loading = false;
          this.initDatasource(true);
          this.cdr.detectChanges();
        },
        (error) => {
          this.message.error(error.statusText);
          this.loading = false;
        }
      );
  }

  areEventTimesValid(start: string, end: string) {
    if (!start) return false;
    if (start != null && end != null) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      if (startDate.getHours() > endDate.getHours() || (startDate.getHours() === endDate.getHours() && startDate.getMinutes() >= endDate.getMinutes())) return false;
      return true;
    }
    return true;
  }

  getConfig() {
    this.contentProductionService
      .getConfig()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        this.config = res;
      });
  }

  export() {
    this.contentProductionService.export(this.selectedDateRange);
  }

  ngAfterViewInit() {}

  undoDeletion(event: ContentProductionEvent) {
    this.loading = true;
    this.contentProductionService
      .update({
        ...event,
        isDeleted: false,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.initDatasource();
      });
  }

  deleteEvent(event: ContentProductionEvent) {
    if (event.isDeleted) {
      return;
    }
    this.loading = true;
    this.contentProductionService
      .deleteEvent(event)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.loading = false;
        this.initDatasource();
      });
  }

  selectFullSeason() {
    this.selectedDateRange = {
      start: +DEFAULT_DATE_PERIOD.start,
      end: +DEFAULT_DATE_PERIOD.end,
    };
    this.initDatasource();
  }

  selectDate() {
    this.dialog
      .open(DateSelectorComponent, {
        width: "350px",
        data: this.selectedDateRange,
      })
      .afterClosed()
      .pipe(filter(Boolean), take(1))
      .pipe(takeUntil(this.destroy$))

      .subscribe(({ dateStart, dateEnd }) => {
        this.selectedDateRange = {
          start: +dateStart,
          end: +dateEnd,
        };
        if (this.loading) {
          this.loading = false;
        }
        this.initDatasource();
      });
  }

  showDeleted() {
    this.dialog
      .open(EventsReactivationTableComponent, {
        width: "98ww",
        panelClass: "no-padding",
        data: {
          isShowingDeleted: true,
        },
      })
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.initDatasource();
      });
  }

  editEvent(event: ContentProductionEvent): void {
    this.openEditingModal(event)
      .pipe(takeUntil(this.destroy$), filter(Boolean))
      .subscribe(() => {
        this.initDatasource();
      });
  }

  navigateToStatistics(): void {
    location.href = "/#!/statistics-overview";
  }

  onCellSelectionOff() {
    const { field, data } = this.selectedCell;
    if (["6", "7"].includes(field) && !this.loading) {
      // 6 === format field
      // 12 === Gast field
      this.saveContentProductionEvent(data);
    }
    this.selectedCell = null;
    this.leagues = this.leaguesCache;
    this.cdr.detectChanges();
  }

  onCellSelected(data: SelectedCell) {
    this.selectedCell = data;
    this.selectedLeague = data.data.competitionName;
    this.leagues = this.leagues.filter((league) => league.competitionIdent === null || league.competitionLabel === data.data.competitionLabel);
    setTimeout(() => {
      if (this.autocomplete) {
        this.autocomplete.openPanel();
      }
      if (this.select) {
        this.select.open();
      }
      // this.header.nativeElement.dispatchEvent(new Event('resize'));
    });
    this.cdr.detectChanges();
  }

  getColorButtonBackgroundColor(competitionLabel: string) {
    const color = this.leagues.filter((l) => l.competitionLabel === competitionLabel);
    if (color.length === 1) {
      return color[0].color;
    }
    return "grey";
  }

  RGBToHex(rgb) {
    // Choose correct separator
    const sep = rgb.indexOf(",") > -1 ? "," : " ";
    // Turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb.substr(4).split(")")[0].split(sep);

    let r = (+rgb[0]).toString(16),
      g = (+rgb[1]).toString(16),
      b = (+rgb[2]).toString(16);

    if (r.length == 1) {
      r = "0" + r;
    }
    if (g.length == 1) {
      g = "0" + g;
    }
    if (b.length == 1) {
      b = "0" + b;
    }

    return "#" + r + g + b;
  }

  getTextColor(bgColor) {
    // bgColor = 'rgb(0,0,0)';
    if (!bgColor?.match(/^rgb/)) {
      return "#000000";
    }
    bgColor = this.RGBToHex(bgColor);
    const whiteContrast = this.getContrast(bgColor, "#ffffff");
    const blackContrast = this.getContrast(bgColor, "#000000");

    return whiteContrast > blackContrast ? "#ffffff" : "#000000";
  }

  getContrast(f, b) {
    const L1 = this.getLuminance(f);
    const L2 = this.getLuminance(b);
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
  }

  getLuminance(hexColor) {
    return 0.2126 * this.getsRGB(hexColor.substr(1, 2)) + 0.7152 * this.getsRGB(hexColor.substr(3, 2)) + 0.0722 * this.getsRGB(hexColor.substr(-2));
  }

  getsRGB(c) {
    return this.getRGB(c) / 255 <= 0.03928 ? this.getRGB(c) / 255 / 12.92 : Math.pow((this.getRGB(c) / 255 + 0.055) / 1.055, 2.4);
  }

  getRGB(c) {
    return parseInt(c, 16) || c;
  }

  colorButtonEnabled(competitionLabel: string) {
    if (this.leagues.filter((l) => l.competitionLabel === competitionLabel).length === 1) {
      return true;
    }
    return false;
  }

  applyColor($event, colorCode: string, buttonEnabled: boolean) {
    $event.stopPropagation();
    if (!this.selectedCell || !buttonEnabled) {
      return;
    }
    this.selectedCell.data.colors[this.selectedCell.field] = colorCode;
    this.contentProductionService.updateCellColor(colorCode, this.selectedCell.field, this.selectedCell.data.id);
    // TODO: Fix workaround for additional roles
    if (this.selectedCell.field === 13) {
      // setTimeout(() => {
      this.initDatasource();
      // }, 250);
    }
  }

  eventUserChanged(newUserName: string, event: ContentProductionEvent, roleKey: string) {
    this.cdr.detectChanges();
    const oldUserName = event[roleKey];
    event[roleKey] = newUserName;
    if (oldUserName === newUserName) {
      return;
    }
    if (oldUserName !== DEFAULT_USER) {
      this.addUserAvailabilityForEventsByDate(oldUserName, event);
      // this.sportEventService.fixUsersIntersections(
      //   this.events.filter(curEvent => curEvent.dateToGetAvailableUsers === event.dateToGetAvailableUsers), false
      // );
    }

    this.saveContentProductionEvent(event);
    this.cdr.detectChanges();
  }

  roleAdditionalsChanged(event: ContentProductionEvent): void {
    this.saveContentProductionEvent(event);
    this.cdr.detectChanges();
  }

  roleAdditionalsInputChanged(event: ContentProductionEvent): void {
    this.additionalEditingEvent = event;
  }

  trackBy(index, item: ContentProductionEvent) {
    return item.id;
  }

  getUserRoleBgColor(event: ContentProductionEvent, roleValue, index) {
    return roleValue === DEFAULT_USER ? "red" : event.colors[index + USER_ROLE_COLUMN_STARTING_INDEX_CONTENT];
  }

  private openEditingModal(event?: ContentProductionEvent): Observable<any> {
    return this.dialog
      .open(EventCreatorFormComponent, {
        width: "450px",
        panelClass: "no-padding",
        data: {
          event,
        },
      })
      .afterClosed();
  }

  private initResizeListener() {
    const interval$ = interval(200).pipe(take(20));
    merge(fromEvent(window, "resize"), interval$)
      .pipe(debounceTime(100))

      .pipe(takeUntil(this.destroy$))

      .subscribe(() => this.resizeTable());
  }

  private resizeTable() {
    const { top } = this.elRef.nativeElement.getBoundingClientRect();
    const ww = window.innerWidth;
    this.tableHeight = `${window.innerHeight - 30 - top - 5 - (ww < 1200 ? scrollBarHeight : 0)}px`; // -72
    this.cdr.detectChanges();
  }

  private addUserAvailabilityForEventsByDate(user: string, except: ContentProductionEvent) {
    this.contentProductionService.addUserAvailabilityForEventsByDateContentProduction(user, except, this.events);
  }

  private initListeners() {
    this.saveEventDebouncer.pipe(debounceTime(500), takeUntil(this.destroy$)).subscribe((event) => this.saveContentProductionEvent(event));

    // this.sportEventService.workerResponse$
    //     .pipe(takeUntil(this.destroy$))
    //     .subscribe(({events, full}) => {
    //
    //
    //         if(full){
    //             this.events = events;
    //         } else {
    //             events.forEach(event => {
    //                 const foundIndex = this.events.findIndex(e => e.id === event.id);
    //                 this.replaceAddtionalsWithActiveInput(event);
    //                 this.events[foundIndex] = event;
    //             });
    //         }
    //         this.cdr.detectChanges();
    //         // hack for changing additionals field while reloading and stuff
    //
    //         this.unsetActiveAdditionalEditingEvent();
    //     });
  }

  private unsetActiveAdditionalEditingEvent() {
    if (!this.selectedCell || this.selectedCell?.field !== 13) {
      setTimeout(() => {
        if (!this.selectedCell || this.selectedCell?.field !== 13) {
          this.additionalEditingEvent = undefined;
        }
      }, 500);
    }
  }

  private initDatasource(forceInit: boolean = false) {
    if (this.loading && !forceInit) {
      return;
    }
    this.loading = true;

    // TODO: uncomment auto updater
    // reset update interval
    // this.updateInterval.unsubscribe();
    // this.updateInterval = this.subscribeAutoUpdater();

    this.cdr.detectChanges();

    this.saveEventDebouncer.pipe(debounceTime(500)).subscribe((event) => this.saveContentProductionEvent(event));

    this.authService
      .hasWritePermission()
      .pipe(takeUntil(this.destroy$))
      .subscribe((permission) => {
        // this.editable = permission;
      });

    //  TODO: it's for testing
    // this.selectedDateRange.start = 1640988000000;
    // this.selectedDateRange.end = 1641988000000;
    this.contentProductionService
      .getContentSportEvents(this.selectedDateRange)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ events, competitionColors, groupMetadata, leagues }) => {
        if (!this.loading) {
          return;
        } // discard result
        this.competitionColors = competitionColors;
        this.events = events;

        const findItem = this.events.flat().find((item) => item.id === this.selectedCell?.data.id);

        if (this.selectedCell?.data && findItem) {
          this.selectedCell.data = findItem;
        }

        this.events = events.map((groupe) => {
          return groupe.map((e) => {
            this.replaceAddtionalsWithActiveInput(e);
            return e;
          });
        });
        this.rowGroupMetadata = groupMetadata;
        this.leaguesCache = cloneDeep(leagues);
        if (this.leagues.length === 0) {
          this.leagues = leagues;
        }
        this.loading = false;
        this.startSkeleton = false;
        this.cdr.detectChanges();
        this.resizeTable();
        this.unsetActiveAdditionalEditingEvent();
      });
  }

  private replaceAddtionalsWithActiveInput(event: ContentProductionEvent) {
    // TODO what if other user edits it after I edited it, then i would not know
    if (event.id === this.additionalEditingEvent?.id) {
      event.roleAdditionals = this.additionalEditingEvent.roleAdditionals;
    }
  }

  private setDefaultDateRange() {
    // TODO: uncomment
    // this.selectedDateRange = {
    //   start: +DEFAULT_DATE_PERIOD.start,
    //   end: +DEFAULT_DATE_PERIOD.end
    // };

    this.form.get("rangePicker").setValue([this.selectedDateRange.start, this.selectedDateRange.end]);
  }

  changeVPU(vpu: any, event: ContentProductionEvent) {
    this.saveContentProductionEvent(event);
    // this.sportEventService.saveVPU(event.id, vpu)
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(res => {
    //     this.setErrors(res, event);
    //     this.cdr.detectChanges();
    //   });
    // this.saveEvent(event);
  }

  openChangedUser($event: boolean, rowData: ContentProductionEvent, roleKey: string) {
    this.loadingVPU = true;
    roleKey = ROLES_DICTIONARY[roleKey];
  }

  private setMaxLength() {
    const element = document.querySelector("mat-select-autocomplete");
    element.addEventListener("click", () => {
      const input = document.querySelector(".box-search input[type=text]");
      if (input) {
        input.setAttribute("maxlength", "4");
      }
    });
  }

  openAutocomplete() {}

  private setErrors(res: VPUResponse, event) {
    Object.entries(ROLES_DICTIONARY).forEach(([key, value]) => {
      event[key + USERS_ERROR_KEY] = null;
    });

    if (!res.hasErrors) {
      return;
    }

    res.roleErrors.map((error) => {
      let a;
      Object.entries(ROLES_DICTIONARY).forEach(([key, value]) => {
        if (value === error.role) {
          a = key;
        }
      });
      event[a + USERS_ERROR_KEY] = error.validationError;
    });
  }

  addEvent(event, groupIndex, rowIndex, rowData: any) {
    event.stopPropagation();
    const emptyEvent = {
      sport: rowData.sport,
      programStart: rowData.programStart,
      programEnd: rowData.programEnd,
      sportName: rowData.sport?.sportNameInternal,
      format: rowData.format,
    };

    this.contentProductionService
      .create(emptyEvent)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        // insert(this.events[groupIndex], rowIndex + 1, res);
        this.initDatasource();
      });
    // const copyItem = cloneDeep(rowData);
    // copyItem.sport.sportNameInternal = copyItem.sport.sportNameInternal + ' Copy';
    //
    // insert(this.events[groupIndex], rowIndex + 1, copyItem);
  }

  // private getEmptyEvent(eventBefore: ContentProductionEvent) {
  //
  //
  //   // return {
  //     // 'id': 0,
  //     // 'sportId': 0,
  //     // 'sport': {
  //     //   'id': 0,
  //     //   'externalId': 0,
  //     //   'icon': 'string',
  //     //   'color': 'string',
  //     //   'sportNameExternal': 'string',
  //     //   'sportNameInternal': 'string',
  //     //   'shortName': 'string',
  //     //   'abbreviation': 'string'
  //     // },
  //     // 'sportEventId': 0,
  //     // 'competitionIdent': 0,
  //     // 'competitionName': 'string',
  //     // 'competitionLabel': 'string',
  //     // 'programStart': '2023-04-18T06:03:15.983Z',
  //     // 'gameDay': 0,
  //     // 'teamNameHome': 'string',
  //     // 'teamNameAway': 'string',
  //     // 'format': 'string',
  //     // 'roleCommenter': 'string',
  //     // 'roleRegie': 'string',
  //     // 'roleEditor': 'string',
  //     // 'rolePresenter': 'string',
  //     // 'roleExpert': 'string',
  //     // 'roleMaz': 'string',
  //     // 'roleAdditionals': 'string',
  //     // 'lastModDateUser': '2023-04-18T06:03:15.983Z',
  //     // 'lastModDateAutoSync': '2023-04-18T06:03:15.983Z',
  //     // 'isDeleted': true,
  //     // 'sportName': 'string',
  //     // 'isAutoSyncDisabled': true,
  //     // 'info': 'string',
  //     // 'vpu': 'string',
  //     // 'content': 'string'
  //   };
  // }
}

function getEventsByDate(dayCode: string, items: ContentProductionEvent[]) {
  return items.filter((i) => i.dateToGetAvailableUsers === dayCode);
}

class SelectedCell {
  data: ContentProductionEvent;
  field; // TODO: sometimes string or number (was string)
}

let scrollBarHeight = 0;
