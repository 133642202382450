import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { downgradeComponent } from "@angular/upgrade/static";
import {  TABLE_COMPONENT_SELECTOR,  TableComponent,} from "./app/dispo/table.component";
import {  STATISTICS_OVERVIEW_SELECTOR,  StatisticsOverviewComponent,} from "./app/statistics-overview/statistics-overview.component";
import {  HEADER_COMPONENT_SELECTOR,  HeaderComponent,} from "./app/_components/header/header.component";
import {  USERS_LIST_COMPONENT_SELECTOR,  UsersListComponent, } from "./app/usersAdministration/users-list/users-list.component";
import {  OVERVIEW_COMPONENT_SELECTOR,  OverviewComponent, } from "./app/overview/overview/overview.component";
import {  CONTENT_COMPONENT_SELECTOR,  ContentProductionComponent, } from "./app/сontent-production/content-production.component";
import {  SEND_OUT_SELECTOR,  SendOutComponent, } from "./app/send-out/send-out.component";
import {  COST_OVERVIEW_SELECTOR,  CostOverviewComponent, } from "./app/cost-overview/cost-overview.component";
import {  COST_CONFIGURATION_SELECTOR,  CostConfigurationComponent, } from "./app/cost-configuration/cost-configuration.component";
import { EDIT_USERS_COMPONENT_SELECTOR, EditUsersAvailabilitiesComponent } from "./app/availabilities/edit-users-availabilities/edit-users-availabilities.component";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// @ts-ignore
const angular = window["angular"];

const angularModule = angular.module("mmi.tp.disposition");

// @ts-ignore

[
  {
    component: TableComponent,
    selector: TABLE_COMPONENT_SELECTOR,
  },
  {
    component: ContentProductionComponent,
    selector: CONTENT_COMPONENT_SELECTOR,
  },
  {
    component: SendOutComponent,
    selector: SEND_OUT_SELECTOR,
  },
  {
    component: HeaderComponent,
    selector: HEADER_COMPONENT_SELECTOR,
  },
  {
    component: UsersListComponent,
    selector: USERS_LIST_COMPONENT_SELECTOR,
  },
  {
    component: OverviewComponent,
    selector: OVERVIEW_COMPONENT_SELECTOR,
  },
  {
    component: StatisticsOverviewComponent,
    selector: STATISTICS_OVERVIEW_SELECTOR,
  },
  {
    component: CostOverviewComponent,
    selector: COST_OVERVIEW_SELECTOR,
  },
  {
    component: CostConfigurationComponent,
    selector: COST_CONFIGURATION_SELECTOR,
  },
  {
    component: EditUsersAvailabilitiesComponent,
    selector: EDIT_USERS_COMPONENT_SELECTOR
  }
]
  .map((c) => ({
    downgraded: downgradeComponent({
      component: c.component,
      propagateDigest: true,
    }),
    selector: toCamelCase(c.selector),
  }))
  .forEach((c) => {
    angularModule.directive(c.selector, c.downgraded);
  });

function toCamelCase(selector: string): string {
  return selector.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  });
}
