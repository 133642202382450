import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PeriodInfo } from "src/app/common/models/period-info";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class EditUserAvailabilitiesService {
  constructor(private readonly http: HttpClient) {}
  private formatDate(date: Date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
  getUsers() {
    return this.http.get<any[]>(environment.rootUrl + `/api/dispositionadmin/users`);
  }

  getPeriodInfo(date: Date) {
    const dateFormatted = this.formatDate(date);
    return this.http.get<PeriodInfo>(environment.rootUrl + `/api/disposition/calendar/${dateFormatted}`);
  }

  getUsersAvailabilities(date: Date) {
    const dateFormatted = this.formatDate(date);
    return this.http.get<any[]>(environment.rootUrl + `/api/dispositionadmin/availabilities/${dateFormatted}`);
  }
  changeUserAvailability(user, date, isAvailable: boolean) {
    const userRoles = user.roles.join(";")
    if (!isAvailable) {
      const dateFormatted = this.formatDate(date);
      return this.http.delete<any[]>(environment.rootUrl + `/api/dispositionadmin/availabilities/${user.abbrev}/${dateFormatted}/${userRoles}`);
    } else {
      const offset = date.getTimezoneOffset();
      const updatedDate = new Date(date.getTime() - offset * 60 * 1000);
      return this.http.put<any[]>(environment.rootUrl + `/api/dispositionadmin/availabilities/${user.abbrev}`, { userAbbrev: user.abbrev, date: updatedDate, roles: userRoles });
    }
  }
}
