import {Component, Inject, OnInit} from '@angular/core';
import {NzMessageService} from 'ng-zorro-antd';

export const OVERVIEW_COMPONENT_SELECTOR = 'app-overview';

import * as _ from 'lodash';

@Component({
  selector: OVERVIEW_COMPONENT_SELECTOR,
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {
  sportsNames: any;
  daysOfCurrentMonth: any;
  sportsTimeline: any;
  mergedAvailabilitiesPerSport: any;
  private currentUser: any;
  private sportsDefinition: any;
  private sportToAbbrevMap: void;
  private usersChangeDates: any;
  private calendar: { periodFinalDeadline: Date; weekendDays: any; periodStart: Date; periodEnd: Date; periodAnnouncedDeadline: Date };
  private currentRole: any;
  private overallRegions: any;
  private currentDate = new Date();
  public _ = _;

  constructor(
    @Inject('appSvc') private appSvc: any,
    @Inject('authSvc') private authSvc: any,
    @Inject('utilitySvc') private utilitySvc: any,
    private message: NzMessageService
  ) {
  }

  ngOnInit(): void {
    this.init();
  }

  init() {
    const currentDate = new Date();
    const loggedInUser = this.authSvc.GetLoggedInUser();
    if (!loggedInUser) {
      location.href = '/login';
      return;
    } else {
      this.currentUser = loggedInUser;
      this.initBackendData(currentDate);
    }
    this.initialize(currentDate, this.currentUser);
  }

  initBackendData(date: Date) {
    this.appSvc.GetSportsDefinitions()
      .then((sportDefs) => {
        this.sportsDefinition = sportDefs;
        this.sportsNames = this.getDistinctSports(sportDefs);
        this.sportToAbbrevMap = this.getSportAbbrevMap(sportDefs);
      });
    this.appSvc.GetSportsTimeline(date)
      .then((timeline) => {
        this.sportsTimeline = timeline;
      });
    this.appSvc.GetPeriodInfo(date)
      .then((periodInfo) => {
        const calendarInitializer = this.utilitySvc.GetInitializedCalendar(periodInfo);
        const userTimezoneOffset = new Date(periodInfo.periodStart).getTimezoneOffset() * 60000;
        const periodFinalDeadline = new Date(periodInfo.periodFinalDeadline);
        const periodFinalDeadlineUtc = new Date(Date.UTC(
            periodFinalDeadline.getFullYear(),
            periodFinalDeadline.getMonth(),
            periodFinalDeadline.getDate(),
            periodFinalDeadline.getHours(),
            periodFinalDeadline.getMinutes())
          + userTimezoneOffset);
        this.calendar = {
          weekendDays: calendarInitializer.weekends,
          periodStart: new Date(new Date(periodInfo.periodStart).getTime() + userTimezoneOffset),
          periodEnd: new Date(new Date(periodInfo.periodEnd).getTime() + userTimezoneOffset),
          periodAnnouncedDeadline:
            periodInfo.periodAnnouncedDeadline
              ? new Date(new Date(periodInfo.periodAnnouncedDeadline).getTime() + userTimezoneOffset)
              : undefined,
          periodFinalDeadline:
            periodInfo.periodFinalDeadline
              ? periodFinalDeadlineUtc
              : undefined
        };
        this.daysOfCurrentMonth = calendarInitializer.calendar;
      });

    this.appSvc.GetAdminChangeDates(date)
      .then((changeDates) => {
        this.usersChangeDates = changeDates;
      });
  }

  showDeleted() {

  }

  export() {

  }

  wasModifiedAfterFinalDeadline(day: any, userAbbrev: any, isDeleted: boolean) {
    if (this.usersChangeDates && this.calendar && this.usersChangeDates[day] && this.usersChangeDates[day][userAbbrev]) {
      const date = new Date(this.usersChangeDates[day][userAbbrev].lastChangeDate);
      return this.usersChangeDates[day][userAbbrev].isDeleted == isDeleted;
    }
  }

  getAdminAvailabilityClasses(day, sportName) {
    if (!this.sportsTimeline) {
      return 'alert ant-alert-success';
    }
    let isGameAvailable = false;
    if (this.sportsTimeline[day] && this.sportsTimeline[day][sportName]) {
      isGameAvailable = true;
    }

    return (isGameAvailable && (!this.mergedAvailabilitiesPerSport
      || !this.mergedAvailabilitiesPerSport[day]
      || !this.mergedAvailabilitiesPerSport[day][sportName]
      || !this.mergedAvailabilitiesPerSport[day][sportName].length)) ? 'alert ant-alert-error' : 'alert ant-alert-success';
  }

  private initialize(date, user) {
    // this.currentRole = location.search().role;
    this.currentRole = '';
    this.appSvc.GetMergedAvailabilitiesPerSport(date, this.currentRole)
      .then((avails) => {

        this.mergedAvailabilitiesPerSport = avails;
      });
    this.appSvc.GetOverallRegions(date)
      .then((regions) => {
        this.overallRegions = regions;
      });
  }

  private getDistinctSports(sports) {
    return Object.keys(sports);
  }

  private getSportAbbrevMap(sportDefs) {

  }

  getUserString(username, index, usersArr) {
    let userStr = username;
    if (index + 1 < usersArr.length) {
      userStr += ',';
    }
    return userStr;
  }

  changeMonth(monthsToAdd) {
    const currentMonth = this.currentDate.getMonth();
    let newMonths = currentMonth + monthsToAdd;
    if (newMonths > 11) {
      newMonths = 0;
      this.currentDate.setFullYear(this.currentDate.getFullYear() + 1);
    }
    if (newMonths < 0) {
      newMonths = 11;
      this.currentDate.setFullYear(this.currentDate.getFullYear() - 1);
    }
    if (this.currentDate.getDate() > 28) {
      // fix shifting from one month to the other one if days > 28 because this could result in skipping one month
      this.currentDate.setDate(28);
    }
    this.currentDate.setMonth(newMonths);
    this.initBackendData(this.currentDate);
    this.initialize(this.currentDate, this.currentUser);
  }

  downloadExcel(roleFilter) {
    this.appSvc.DownloadAvailabilitiesExcel(this.currentDate, roleFilter);
  }

  finalizeMonth() {
    const finalDeadline = new Date();
    this.appSvc.AddOrUpdateDeadline(this.calendar.periodStart, this.calendar.periodAnnouncedDeadline, finalDeadline)
      .then(() => {
        const userTimezoneOffset = new Date(finalDeadline).getTimezoneOffset() * 60000;
        this.calendar.periodFinalDeadline = new Date(finalDeadline.getTime() + userTimezoneOffset);
        this.message.success('Monat erfolgreich abgeschlossen');
      });
  }

  getMergedAvailabilitiesPerSport(day: number, sportName: string) {
    if (!this.mergedAvailabilitiesPerSport[day]) {
      return [];
    }
    return this.mergedAvailabilitiesPerSport[day][sportName];
  }
}
