import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {AutoDestroyService} from '../services/autodestroy.service';
import {takeUntil} from 'rxjs/operators';

@Directive({
    selector: '[isPermissions]',
    providers: [AutoDestroyService]
})
export class UserHasPermissionsDirective {
    private hasView = false;

    @Input() set isPermissions(permission: string) {
        this.checkPermission(permission);
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private vcr: ViewContainerRef,
        private destroy: AutoDestroyService,
        private readonly authService: AuthService
    ) {
    }

    private checkPermission(permision: string) {
        this.authService.hasWritePermission()
            .pipe(
                takeUntil(this.destroy)
            ).subscribe(hasDispoWriteAccess => {
            if (hasDispoWriteAccess && !this.hasView) {
                this.vcr.createEmbeddedView(this.templateRef);
                this.hasView = true;
            } else if (!hasDispoWriteAccess && this.hasView) {
                this.vcr.clear();
                this.hasView = false;
            }
        });

    }

}