import {ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {trigger} from '@angular/animations';
import {DEFAULT_USER} from '../../constants/sport-event.constant';
import {sortBy, sortedUniqBy} from 'lodash';

@Component({
  animations: [trigger('', [])],
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectComponent),
      multi: true
    }
  ]
})
export class UserSelectComponent implements ControlValueAccessor {

  @Input() debug: boolean

  @Input() set users(users: string[]) {
    this.options = [{name: DEFAULT_USER}];
    const options = [{name: DEFAULT_USER}];
    if (!users) {
      return;
    }
    if (!!this.model && !users.find(name => name === this.model)) {
      options.push({name: this.model})
    }
    this.options =
      sortedUniqBy(
        sortBy(options.concat(users.map(name => ({name}))), ['name']),
        (o) => o.name
      )

  }

  model: string;
  options: Option[] = [];

  constructor(
    private cdr: ChangeDetectorRef
  ) {
  }

  valueChanged(value: string) {
    this.model = value;
    this.onChange(value)
  }

  onChange: any = () => {
  };
  onTouch: any = () => {
  };

  set value(val: string) {
    if (val !== undefined && this.model !== val) {
      if (val && !this.options.find(u => u.name === val)) {
        this.options.push({name: val, disabled: true})
      }
      this.model = val;
    }
  }

  writeValue(value: string) {
    this.value = value
    this.cdr.detectChanges();
  }

  registerOnChange(fn: any) {
    this.onChange = fn
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn
  }

  trackBy(index, item: Option) {
    return item.name
  }

}

class Option {
  name: string
  disabled?: boolean
}
